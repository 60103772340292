import { useNavigate } from "react-router-dom";
import styles from "./customerItem.module.scss";

import EmailIcon from "../../../static/svg/EmailIcon";

const CustomerItem = ({ customer }) => {
  let navigate = useNavigate();

  return (
    <div className={styles.container} onClick={() => navigate(`/customers/${customer._id}`)}>
      <h4>{customer.name}</h4>

      <div className={styles.emailInfo}>
        <EmailIcon />
        <p>{customer.email}</p>
      </div>
    </div>
  );
};

export default CustomerItem;
