function ArrowDown({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.53033 9.46967C7.23744 9.17678 6.76256 9.17678 6.46967 9.46967C6.17678 9.76256 6.17678 10.2374 6.46967 10.5303L7.53033 9.46967ZM12 15L11.4697 15.5303C11.7626 15.8232 12.2374 15.8232 12.5303 15.5303L12 15ZM17.5303 10.5303C17.8232 10.2374 17.8232 9.76256 17.5303 9.46967C17.2374 9.17678 16.7626 9.17678 16.4697 9.46967L17.5303 10.5303ZM6.46967 10.5303L11.4697 15.5303L12.5303 14.4697L7.53033 9.46967L6.46967 10.5303ZM12.5303 15.5303L17.5303 10.5303L16.4697 9.46967L11.4697 14.4697L12.5303 15.5303Z"
        fill="#191919"
      />
    </svg>
  );
}

export default ArrowDown;
