import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";

const LandingPageWrapper = styled.div`
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const LandingPage = (props) => {
  let navigate = useNavigate();

  // navigate("/dashboard");

  return <LandingPageWrapper></LandingPageWrapper>;
};

export default LandingPage;
