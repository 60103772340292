import styles from "./Alert.module.scss";
import { IoCloseOutline } from "react-icons/io5";
import AlertSuccessIcon from "../../../static/svg/AlertSuccessIcon";
import AlertInfoIcon from "../../../static/svg/AlertInfoIcon";
import AlertErrorIcon from "../../../static/svg/AlertErrorIcon";
import { useEffect } from "react";

const Alert = ({ alert, close }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      close();
    }, 5000);
  }, []);

  const alertBackground = {
    backgroundColor: alert.type === "success" ? "#B6F2B8" : alert.type === "error" ? "#F2BBBE" : "#C8E1F8",
  };

  const alertIcon = () => {
    if (alert.type === "success") {
      return <AlertSuccessIcon />;
    } else if (alert.type === "info") {
      return <AlertInfoIcon />;
    } else if (alert.type === "error") {
      return <AlertErrorIcon />;
    }
  };

  return (
    <div className={styles.alert} style={alertBackground}>
      <div className={styles.typeIcon}>{alertIcon()}</div>
      <div className={styles.message}>
        <p>{alert.message}</p>
      </div>
      <div onClick={close} className={styles.closeButton}>
        <IoCloseOutline size="24" />
      </div>
    </div>
  );
};

export default Alert;
