import { useState } from "react";
import styles from "./photo.module.scss";
import { BsThreeDots } from "react-icons/bs";
import axios from "axios";
import { useParams } from "react-router-dom";

const Photo = ({ photo, photos, index, galleryStatus, handleDeletedPhotos, getGallery }) => {
  const [showPhotoSettings, setShowPhotoSettings] = useState(false);

  const params = useParams();

  const setCover = async (index) => {
    const newCover = photo;
    let updatedPhotos = photos;

    updatedPhotos.splice(index, 1);
    updatedPhotos.unshift(newCover);

    const data = {
      photos: updatedPhotos,
    };

    await axios
      .put(`/galleries/${params.id}`, data)
      .then((res) => {
        getGallery();
      })
      .catch((err) => {
        console.log(err);
      });

    setShowPhotoSettings(false);
  };

  return (
    <div
      className={`${styles.photo} ${photo.selected ? styles.selected : ""} ${photo.edited ? styles.edited : ""} ${
        photo.marked ? styles.marked : ""
      }`}
    >
      <div className={styles.controlsBox}>
        {galleryStatus === "new" ? (
          <input
            type="checkbox"
            name="photo"
            id="photo"
            className={styles.deleteInput}
            onChange={(event) => handleDeletedPhotos(photo, event)}
          />
        ) : (
          <input
            type="checkbox"
            name="photo"
            id="photo"
            disabled
            className={styles.deleteInput}
            onChange={(event) => handleDeletedPhotos(photo, event)}
          />
        )}
        <div onClick={() => setShowPhotoSettings(!showPhotoSettings)} className={styles.photoSettingsIcon}>
          <BsThreeDots size={20} color="#999999" style={{ transform: "rotate(90deg)" }} />
        </div>
      </div>

      <a href={photo.url} target="blank">
        <img src={`${photo.url}`} alt="photo" />
      </a>
      {photo.title.length > 22 ? (
        <p>
          {photo.title.substring(0, 10)}...
          {photo.title.substr(photo.title.length - 10)}
        </p>
      ) : (
        <p>{photo.title}</p>
      )}
      {showPhotoSettings && (
        <div className={styles.photoSettings}>
          <ul className={styles.settingsList}>
            <li onClick={() => setCover(index)} className={styles.setting}>
              Ustaw jako okładkę albumu
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Photo;
