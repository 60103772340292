import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../store/AppContextProvider";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./newCustomer.module.scss";
import axios from "axios";

import Page from "../../../components/layout/page/Page";
import FormInput from "../../../components/layout/Form/form-input/FormInput";
import FormTextarea from "../../../components/layout/Form/FormTextarea";
import Alert from "../../../components/layout/Alert/Alert";
import ReturnButton from "../../../components/layout/returnButton/ReturnButton";
import PrimaryButton from "../../../components/layout/primaryButton/PrimaryButton";

const NewCustomerPage = (props) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const { user, setUser } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  const createCustomer = async (e) => {
    e.preventDefault();
    let data = {
      name: name,
      email: email,
      organizationEmail: user.email,
      phone: phone,
      notes: notes,
      password: password,
    };

    await axios
      .post("/customers", data)
      .then((res) => {
        // Jeżeli użytkownik przychodzi ze strony dodawania galerii, po dodaniu klienta przekierowuje go automatycznie do wcześniejszej strony.
        if (location.state?.from === "galleries/new-gallery") {
          navigate(`/galleries/new-gallery?id=${res.data.newCustomer._id}`);
        } else {
          navigate(`/customers/${res.data.newCustomer._id}`);
        }
      })
      .catch((err) => setMessage(err.response.data.message));
  };

  return (
    <Page>
      <div className={styles.container}>
        <ReturnButton title="Powrót do listy klientów" url="/customers" />
        <div className={styles.headerTitle}>
          <h3>Nowy klient</h3>
          <p>Pamiętaj, że po stworzeniu klienta, adresu email i hasła nie będzie można edytować.</p>
        </div>
        <form onSubmit={createCustomer} className={styles.form}>
          <FormInput
            onChange={(e) => setName(e.target.value)}
            value={name}
            type="text"
            label="Nazwa klienta"
            name="name"
            pattern=".{3,50}"
            errorMessage="Nazwa klienta jest wymagana i musi zawierać od 3 do 50 znaków."
            required
            isRequired
            className={styles.input}
          />
          <FormInput
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="email"
            label="Adres email"
            name="email"
            pattern=".{3,50}"
            errorMessage="Podaj poprawny adres email"
            required
            isRequired
            className={styles.input}
          />
          <div className={styles.secondary}>
            <FormInput
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="text"
              label="Hasło"
              name="password"
              pattern="^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$"
              errorMessage="Hasło musi składać się z 8 - 20 znaków (bez polskich liter) oraz zawierać przynajmniej jedną cyfrę, jedną wielką literę i jeden znak specjalny."
              required
              isRequired
              className={styles.input}
            />
            <FormInput
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              type="number"
              label="Numer telefonu"
              name="phone"
              pattern=""
              errorMessage=""
              className={styles.input}
            />
          </div>
          <FormTextarea
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
            type="text"
            label="Notatki"
            name="notes"
            pattern=""
            errorMessage=""
            className={styles.textarea}
          />
          <div className={styles.actionButtons}>
            <PrimaryButton
              // onClick={editCustomer}
              title="Stwórz klienta"
              backgroundColor="#0099F0"
              borderColor="#0099F0"
              color="#FFF"
            />
          </div>
        </form>
      </div>
      {message ? <Alert message={message} close={() => setMessage("")} /> : ""}
    </Page>
  );
};

export default NewCustomerPage;
