import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./customersPage.module.scss";
import axios from "axios";

import Page from "../../../components/layout/page/Page";
import CustomersList from "../../../components/customers/customersList/CustomersList";
import LoadingBar from "../../../components/layout/LoadingBar";
import PrimaryButton from "../../../components/layout/primaryButton/PrimaryButton";
import CreateFirstGalleryImage from "../../../static/svg/CreateFirstGalleryImage";

const CustomersPage = (props) => {
  const [customers, setCustomers] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const getCustomers = async () => {
      setLoading(true);

      await axios.get("/customers").then((res) => {
        setCustomers(res.data);
      });

      setLoading(false);
    };
    getCustomers();
  }, []);

  const search = (data) => {
    return data.filter(
      (customer) =>
        customer.name.toLowerCase().includes(query.toLowerCase()) ||
        customer.email.toLowerCase().includes(query.toLowerCase())
    );
  };

  const customersList = () => {
    if (!customers || customers.length === 0) {
      return (
        <div className={styles.createFirstCustomer}>
          <CreateFirstGalleryImage />
          <h3 className={styles.createCustomerHeader}>Dodaj swojego pierwszego klienta i rozpocznij pracę</h3>
          <PrimaryButton
            onClick={() => navigate("/customers/new-customer")}
            title="Dodaj klienta"
            backgroundColor="#0099f0"
            border="none"
            color="#FFF"
          />
        </div>
      );
    } else {
      return <CustomersList customers={search(customers)} />;
    }
  };

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.headerTitle}>
          <h3>Twoi klienci</h3>
          <PrimaryButton
            onClick={() => {
              navigate("new-customer");
            }}
            title="Dodaj klienta"
            backgroundColor="#0099F0"
            color="#FFF"
          />
        </div>
        <div className={styles.controls}>
          <div className={styles.filters}>
            <input
              onChange={(e) => setQuery(e.target.value)}
              type="text"
              placeholder="wyszukaj klienta"
              className={styles.search}
            />
          </div>
        </div>
        {loading ? <LoadingBar /> : customersList()}
      </div>
    </Page>
  );
};

export default CustomersPage;
