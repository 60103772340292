import { useState, useEffect } from "react";
import styles from "./page.module.scss";
import Topbar from "../topbar/Topbar";
import MainMenu from "../main-menu/MainMenu";

const Page = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsMenuOpen(true);
      } else {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className={styles.container}>
      <div className={styles.page}>
        {isMenuOpen ? <MainMenu /> : ""}
        <div className={styles.pageContent}>
          <Topbar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Page;
