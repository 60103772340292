import axios from "axios";
import styles from "./customerLogin.module.scss";
import { useState, useContext } from "react";
import { UserContext } from "../../../store/AppContextProvider";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../components/layout/Navbar";
import LoginForm from "../components/loginForm/LoginForm";

const CustomerLogin = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    const user = {
      email: email,
      password: password,
    };

    if (!email || !password) {
      setErrors("Adres email i hasło są wymagane");
    } else {
      await axios
        .post("/customers/login", user)
        .then((res) => {
          setUser(res.data.user);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", res.data.user.role);
          localStorage.setItem("organization", res.data.user.organization_name);

          axios.defaults.baseURL = process.env.REACT_APP_API_URL;
          axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

          setTimeout(() => {
            navigate(`/customer-panel`);
            setLoading(false);
          }, 2000);
        })
        .catch((error) => {
          setErrors(error.response.data.message);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Navbar />
      <LoginForm setEmail={setEmail} setPassword={setPassword} errors={errors} login={login} loading={loading} />
    </>
  );
};

export default CustomerLogin;
