import styles from "./customerGalleryCard.module.scss";
import { useNavigate } from "react-router-dom";

const CustomerGalleryItem = (props) => {
  let navigate = useNavigate();

  const openGallery = () => {
    navigate(`/customer-panel/gallery/${props.gallery._id}`);
  };

  return (
    <div className={styles.container} onClick={openGallery}>
      <div className={styles.cover}>
        <img src={props.gallery.photos[0]?.url} alt="cover" />
      </div>
      <div>
        <h4>{props.gallery.title}</h4>
        <p>Data: {props.gallery.date}</p>
      </div>
    </div>
  );
};

export default CustomerGalleryItem;
