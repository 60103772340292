function CloseButton({ color, width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.3668 19.3193L14.5475 11.5L22.3668 3.68066C23.2111 2.83646 23.2111 1.47735 22.3668 0.633151C21.5226 -0.21105 20.1635 -0.21105 19.3193 0.633151L11.5 8.45249L3.68066 0.633151C2.83646 -0.21105 1.47735 -0.21105 0.633151 0.633151C-0.21105 1.47735 -0.21105 2.83646 0.633151 3.68066L8.45249 11.5L0.633151 19.3193C-0.21105 20.1635 -0.21105 21.5226 0.633151 22.3668C1.47735 23.2111 2.83646 23.2111 3.68066 22.3668L11.5 14.5475L19.3193 22.3668C20.1635 23.2111 21.5226 23.2111 22.3668 22.3668C23.2051 21.5226 23.2051 20.1576 22.3668 19.3193Z"
        fill={color}
      />
    </svg>
  );
}

export default CloseButton;
