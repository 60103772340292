import { useContext } from "react";
import styles from "./progressBar.module.css";
import { UserContext } from "../../../store/AppContextProvider";
import { BsFillCameraFill } from "react-icons/bs";

const ProgressBar = (props) => {
  const { uploadedPhotoCount, setUploadedPhotoCount } = useContext(UserContext);

  return (
    <div className={styles.container}>
      <p>Wysyłanie zdjęć na serwer.</p>
      <p>
        Zdjęcie {uploadedPhotoCount} z {props.photosAmount}
      </p>
      <div className={styles.camera}>
        <BsFillCameraFill />
      </div>
    </div>
  );
};

export default ProgressBar;
