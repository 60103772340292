function EmailIcon({ color }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5788 5.24543L3.24364 4.8744L3.24364 4.8744L3.5788 5.24543ZM11.1785 10.4881L10.825 10.1346L10.825 10.1346L11.1785 10.4881ZM10 15.75C6.977 15.75 5.1572 15.3742 4.08131 14.5248C3.04439 13.7062 2.58337 12.3459 2.58337 10H1.58337C1.58337 12.4335 2.05373 14.1982 3.46166 15.3097C4.83063 16.3905 6.96916 16.75 10 16.75V15.75ZM17.4167 10C17.4167 12.3459 16.9557 13.7062 15.9188 14.5248C14.8429 15.3742 13.0231 15.75 10 15.75V16.75C13.0309 16.75 15.1695 16.3905 16.5384 15.3097C17.9463 14.1982 18.4167 12.4335 18.4167 10H17.4167ZM10 4.25C13.191 4.25 15.0373 4.66906 16.0861 5.61646L16.7564 4.8744C15.3992 3.64834 13.1921 3.25 10 3.25V4.25ZM16.0861 5.61646C17.0032 6.4449 17.4167 7.78034 17.4167 10H18.4167C18.4167 7.70004 17.9973 5.99531 16.7564 4.8744L16.0861 5.61646ZM2.58337 10C2.58337 7.78034 2.99686 6.4449 3.91397 5.61646L3.24364 4.8744C2.00276 5.99531 1.58337 7.70004 1.58337 10H2.58337ZM3.91397 5.61646C4.96277 4.66906 6.80907 4.25 10 4.25V3.25C6.80802 3.25 4.60092 3.64834 3.24364 4.8744L3.91397 5.61646ZM8.46796 10.8417C9.31409 11.6878 10.686 11.6878 11.5321 10.8417L10.825 10.1346C10.3694 10.5902 9.63068 10.5902 9.17507 10.1346L8.46796 10.8417ZM3.22525 5.59898L8.46796 10.8417L9.17507 10.1346L3.93236 4.89188L3.22525 5.59898ZM11.5321 10.8417L16.7748 5.59898L16.0677 4.89188L10.825 10.1346L11.5321 10.8417Z"
        fill="#191919"
        fillOpacity="0.7"
      />
    </svg>
  );
}

export default EmailIcon;
