import { useNavigate } from "react-router-dom";

import styles from "./topbar.module.scss";
import UserSettings from "./user-settings/UserSettings";
import MenuHamburger from "../../../static/svg/MenuHamburger";
import MenuClose from "../../../static/svg/MenuClose";

const Topbar = ({ toggleMenu, isMenuOpen }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <UserSettings />
      <div className={styles.menuTrigger} onClick={toggleMenu}>
        {isMenuOpen ? <MenuClose /> : <MenuHamburger />}
      </div>
    </div>
  );
};

export default Topbar;
