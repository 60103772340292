import { useState } from "react";
import styles from "./formInput.module.scss";

import { IoEye } from "react-icons/io5";

const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, placeholder, errorMessage, onChange, id, isRequired, passwordEye, showPassword, ...inputProps } =
    props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <div className={styles.inputContainer}>
      <div className={styles.labelContainer}>
        <label>
          {label} {isRequired ? <p className={styles.dot}>*</p> : ""}
        </label>
        {passwordEye ? <IoEye className={styles.eyeIcon} onClick={() => showPassword()} size={20} /> : ""}
      </div>
      <input
        {...inputProps}
        onChange={onChange}
        onBlur={handleFocus}
        placeholder={placeholder}
        onFocus={() => inputProps.name === "confirmPassword" && setFocused(true)}
        focused={focused.toString()}
        style={{ background: `${props.background}` }}
        min="0"
      />
      <span>{errorMessage}</span>
    </div>
  );
};

export default FormInput;
