import { useNavigate } from "react-router-dom";
import Links from "./links/Links";
import styles from "./menu.module.scss";

const Menu = (props) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <h1 onClick={() => navigate("/dashboard")} className={styles.logo}>
        Maslado
      </h1>
      <Links />
    </div>
  );
};

export default Menu;
