import styles from "./confirmationBox.module.scss";

const ConfirmationBox = (props) => {
  return (
    <div className={styles.container} onClick={props.negativeAction}>
      <div className={styles.questionModal}>
        <p>{props.question}</p>
        <div className={styles.buttonsWrapper}>
          <button className={styles.positiveAction} onClick={props.positiveAction}>
            Tak
          </button>
          <button className={styles.negativeAction} onClick={props.negativeAction}>
            Nie
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationBox;
