const ArrowCircleUp = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7L12.5303 6.46967C12.2374 6.17678 11.7626 6.17678 11.4697 6.46967L12 7ZM11.25 17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17H11.25ZM15.4697 11.5303C15.7626 11.8232 16.2374 11.8232 16.5303 11.5303C16.8232 11.2374 16.8232 10.7626 16.5303 10.4697L15.4697 11.5303ZM7.46967 10.4697C7.17678 10.7626 7.17678 11.2374 7.46967 11.5303C7.76256 11.8232 8.23744 11.8232 8.53033 11.5303L7.46967 10.4697ZM11.25 7V17H12.75V7H11.25ZM16.5303 10.4697L12.5303 6.46967L11.4697 7.53033L15.4697 11.5303L16.5303 10.4697ZM11.4697 6.46967L7.46967 10.4697L8.53033 11.5303L12.5303 7.53033L11.4697 6.46967ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75Z"
        fill="#191919"
      />
    </svg>
  );
};

export default ArrowCircleUp;
