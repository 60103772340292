import styles from "./primaryButton.module.scss";

const PrimaryButton = ({ title, backgroundColor, borderColor, color, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        color: color,
      }}
      className={styles.primaryButton}
    >
      {title}
    </button>
  );
};

export default PrimaryButton;
