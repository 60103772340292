import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../../../components/layout/primaryButton/PrimaryButton";
import styles from "./customerPhoto.module.scss";

import PrintingsChart from "../PrintingsChart";

const CustomerPhoto = ({
  photo,
  index,
  openGalleryModal,
  selectPhoto,
  unselectPhoto,
  openPrintingsChart,
  closePrintingsChart,
  updatePrintings,
  ifPrintings,
  downloadSinglePhoto,
}) => {
  const [currentPhoto, setCurrentPhoto] = useState(null);

  useEffect(() => {
    if (photo) {
      setCurrentPhoto(photo);
    }
  }, [photo]);

  return (
    <div className={styles.container}>
      {photo.showPrintings ? (
        <PrintingsChart
          photo={photo}
          closePrintingsChart={closePrintingsChart}
          func={(updatedPhoto) => updatePrintings(index, updatedPhoto)}
          openPrintingsChart={openPrintingsChart}
        />
      ) : (
        ""
      )}
      <div className={styles.thumbnail} onClick={() => openGalleryModal(index)}>
        <img src={photo.url} alt="photo" />
      </div>
      <div className={styles.controls}>
        {photo.marked ? (
          <div className={styles.buttons}>
            <PrimaryButton
              title="Wybrane"
              backgroundColor="#0096ea"
              borderColor="#0096ea"
              color="#FFF"
              onClick={() => {
                unselectPhoto(index);
              }}
            ></PrimaryButton>
            {ifPrintings ? (
              <PrimaryButton
                title="Wydruki"
                backgroundColor="#FFF"
                borderColor="#0096ea"
                color="#0096ea"
                onClick={() => {
                  openPrintingsChart(index);
                }}
              ></PrimaryButton>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {photo.selected ? (
          <div className={styles.buttons}>
            <PrimaryButton title="Wysłane" backgroundColor="#FFF" borderColor="#191919" color="#191919"></PrimaryButton>
            {ifPrintings ? (
              <PrimaryButton
                title="Wydruki"
                backgroundColor="#FFF"
                borderColor="#0096ea"
                color="#0096ea"
                onClick={() => {
                  openPrintingsChart(index);
                }}
              ></PrimaryButton>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {!photo.marked && !photo.selected && !photo.edited ? (
          <PrimaryButton
            onClick={() => {
              selectPhoto(index);
            }}
            title="Wybierz"
            backgroundColor="#FFF"
            borderColor="#0096ea"
            color="#0096ea"
          ></PrimaryButton>
        ) : (
          ""
        )}
        {photo.edited ? (
          <PrimaryButton
            onClick={() => {
              downloadSinglePhoto(photo);
            }}
            title="Pobierz"
            backgroundColor="#FFF"
            borderColor="#0096ea"
            color="#0096ea"
          ></PrimaryButton>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CustomerPhoto;
