import styles from "./links.module.scss";
import { NavLink } from "react-router-dom";
import MenuDashboardIcon from "../../../../static/svg/MenuDashboardIcon";
import MenuCustomersIcon from "../../../../static/svg/MenuCustomersIcon";
import MenuGalleriesIcon from "../../../../static/svg/MenuGalleriesIcon";
import MenuContactIcon from "../../../../static/svg/MenuContactIcon";
import MenuSettingsIcon from "../../../../static/svg/MenuSettingsIcon";
import ArrowCircleUp from "../../../../static/svg/ArrowCircleUp";

const MenuLinks = (props) => {
  return (
    <nav id={styles.navigation} className={styles.container}>
      <ul className={styles.list}>
        <div className={styles.primaryLinks}>
          <li className={styles.listItem}>
            <NavLink
              to="/dashboard"
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#ecf0f4" : "",
                };
              }}
            >
              <div className={styles.icon}>
                <MenuDashboardIcon />
              </div>
              Pulpit
            </NavLink>
          </li>
          <li className={styles.listItem}>
            <NavLink
              to="/galleries"
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#ecf0f4" : "",
                };
              }}
            >
              <div className={styles.icon}>
                <MenuGalleriesIcon />
              </div>
              Galerie
            </NavLink>
          </li>
          <li className={styles.listItem}>
            <NavLink
              to="/customers"
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#ecf0f4" : "",
                };
              }}
            >
              <div className={styles.icon}>
                <MenuCustomersIcon />
              </div>
              Klienci
            </NavLink>
          </li>
        </div>

        <div className={styles.secondaryLinks}>
          <li className={styles.packagesLink}>
            <div className={styles.icon}>
              <ArrowCircleUp />
            </div>
            <span className={styles.question}>Potrzebujesz więcej galerii?</span>
            <NavLink
              to="/orders"
              // style={({ isActive }) => {
              //   return {
              //     backgroundColor: isActive ? "#ecf0f4" : "",
              //   };
              // }}
            >
              Wykup pakiet
            </NavLink>
          </li>
          <li className={styles.listItem}>
            <NavLink
              to="/contact"
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#ecf0f4" : "",
                };
              }}
            >
              <div className={styles.icon}>
                <MenuContactIcon />
              </div>
              Kontakt
            </NavLink>
          </li>
          <li className={styles.listItem}>
            <NavLink
              to="/settings"
              style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#ecf0f4" : "",
                };
              }}
            >
              <div className={styles.icon}>
                <MenuSettingsIcon />
              </div>
              Ustawienia
            </NavLink>
          </li>
        </div>
      </ul>
    </nav>
  );
};

export default MenuLinks;
