import { useState } from "react";
import styles from "./loginForm.module.scss";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { IoEye } from "react-icons/io5";

// location.pathname === "/login"

const LoginForm = ({ setEmail, setPassword, errors, login, loading }) => {
  const [passwordShow, setPasswordShow] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const toggleButton = () => {
    if (location.pathname === "/login") {
      return (
        <>
          <div
            onClick={() => navigate("/login")}
            className={styles.toggleButton}
            style={{
              background: "#0096ea",
              color: "white",
            }}
          >
            fotograf
          </div>
          <div onClick={() => navigate("/customer-login")} className={styles.toggleButton}>
            klient
          </div>
        </>
      );
    } else if (location.pathname === "/customer-login") {
      return (
        <>
          <div onClick={() => navigate("/login")} className={styles.toggleButton}>
            fotograf
          </div>
          <div
            onClick={() => navigate("/customer-login")}
            className={styles.toggleButton}
            style={{
              background: "#0096ea",
              color: "white",
            }}
          >
            klient
          </div>
        </>
      );
    }
  };

  return (
    <div className={styles.container}>
      <form onSubmit={login} className={styles.loginForm}>
        <div className={styles.header}>
          <h2>Zaloguj się jako</h2>
          <div className={styles.toggleButtons}>{toggleButton()}</div>
        </div>
        <div className={styles.input}>
          <label htmlFor="email">Adres email</label>
          <input onChange={(e) => setEmail(e.target.value)} type="email" name="email" placeholder="Adres email" />
        </div>
        <div className={styles.input}>
          <label htmlFor="password">Hasło</label>
          <IoEye className={styles.eyeIcon} onClick={() => setPasswordShow(!passwordShow)} size={20} />
          <input
            onChange={(e) => setPassword(e.target.value)}
            type={passwordShow ? "text" : "password"}
            name="password"
            placeholder="Hasło"
          />
        </div>
        {errors ? <p style={{ color: "red" }}>{errors}</p> : ""}
        {location.pathname === "/login" ? (
          <>
            {" "}
            <p>
              Nie posiadasz konta?
              <Link to="/registration">
                <span> Zarejestruj się</span>
              </Link>
            </p>
            <p>
              <Link to="/reset-password">
                <span>Zapomniałeś hasła?</span>
              </Link>
            </p>
          </>
        ) : (
          <p>Dane logowania znajdziesz w wiadomości email otrzymanej od fotografa.</p>
        )}
        <button type="submit" className={styles.loginButton}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <div className={styles.ball1}></div>
              <div className={styles.ball2}></div>
              <div className={styles.ball3}></div>
            </div>
          ) : (
            "Zaloguj się"
          )}
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
