import { render } from "react-dom";
import App from "./App";
import AppContextProvider from "./store/AppContextProvider";

// GLOBAL STYLES
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/global.scss";
import "react-image-crop/dist/ReactCrop.css";

const rootElement = document.getElementById("root");

render(
  <AppContextProvider>
    <App />
  </AppContextProvider>,
  rootElement
);
