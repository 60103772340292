import { BrowserRouter } from "react-router-dom";
import { createContext, useEffect, useState, useContext } from "react";
import { UserContext } from "./store/AppContextProvider";
import axios from "axios";

import Router from "./router/Router";

const App = () => {
  const { user, setUser } = useContext(UserContext);

  // AXIOS defaults
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

  const getUser = async () => {
    await axios.get("/users").then((res) => {
      let data = res.data;
      setUser(data);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
};

export default App;
