import React from "react";
import styles from "./carouselPhoto.module.scss";
import PrintingsChartCarousel from "../PrintingsChartCarousel";

const CarouselPhoto = (props) => {
  const photoButtons = () => {
    if (props.photos[props.activeIndex].marked) {
      return (
        <button
          onClick={() => {
            props.unselectPhoto(props.photos[props.activeIndex]);
          }}
          className={styles.selectPhoto}
          style={{ background: "#83F29C" }}
        >
          Wybrano
        </button>
      );
    } else if (props.photos[props.activeIndex].selected) {
      return (
        <button className={styles.selectPhoto} style={{ background: "grey", width: "180px" }}>
          Wysłano do fotografa
        </button>
      );
    } else if (!props.photos[props.activeIndex].edited) {
      return (
        <button
          onClick={() => {
            props.selectPhoto(props.photos[props.activeIndex]);
          }}
          className={styles.selectPhoto}
        >
          Wybieram
        </button>
      );
    }
  };

  return (
    <div className={styles.photo}>
      <img src={props.photos[props.activeIndex].url} alt="" />
      <div className={styles.controls}>
        <div className="d-flex">
          {photoButtons()}
          {(props.photos[props.activeIndex].marked || props.photos[props.activeIndex].selected) && props.ifPrintings ? (
            <button
              onClick={() => props.openPrintingsChart(props.activeIndex)}
              className={styles.openPrintings}
              style={
                props.photos[props.activeIndex].printingsPrice ? { background: "#83F29C" } : { background: "inherit" }
              }
            >
              Wydruki
            </button>
          ) : (
            ""
          )}
          {props.photos[props.activeIndex].edited ? (
            <button className={styles.downloadPhoto}>
              <a
                href={`https://res.cloudinary.com/roehill/image/upload/fl_attachment:${props.photos[
                  props.activeIndex
                ].title
                  .replace(/[^a-zA-Z0-9 ]/g, "")
                  .slice(0, -3)}/${props.photos[props.activeIndex].id}`}
              >
                Pobierz
              </a>
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex">
          <div className={styles.chosenShots}>
            <p>
              Ilość wybranych ujęć: <span>{props.selectedShotsQt}</span>
            </p>
          </div>
          <div className={styles.totalPrice}>
            <p>Do zapłaty: {props.totalPrice()}</p>
          </div>
        </div>
      </div>
      {props.photos[props.activeIndex].showPrintings ? (
        <PrintingsChartCarousel
          photo={props.photos[props.activeIndex]}
          additionalPrintings={props.additionalPrintings}
          closeChart={() => props.openPrintingsChart(props.activeIndex)}
          func={(updatedPhoto) => props.updatePrintings(props.activeIndex, updatedPhoto)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CarouselPhoto;
