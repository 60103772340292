import { useContext, useEffect, useState } from "react";
import styles from "./orderReturn.module.scss";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../store/AppContextProvider";

const OrderReturnPage = () => {
  const [order, setOrder] = useState({});
  const [isVerified, setIsVerified] = useState();
  const [message, setMessage] = useState("");

  const { user, setUser } = useContext(UserContext);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getOrder();

    setTimeout(getOrder, 3000);
  }, []);

  const getOrder = async () => {
    const sessionId = searchParams.get("sessionId");
    console.log(sessionId);
    await axios
      .get(`/orders/${sessionId}`)
      .then((res) => {
        setOrder(res.data);
      })
      .catch((err) => console.log(err));
  };

  const verifyTransaction = async () => {
    const orderId = searchParams.get("orderId");
    let amount = 0;
    let sessionId = "";

    await axios
      .get(`/orders/${orderId}`)
      .then((res) => {
        amount = res.data.amount;
        sessionId = res.data.sessionId;
      })
      .catch((err) => console.log(err));

    const data = {
      sessionId: sessionId,
      orderId: orderId,
      amount: amount,
    };
    console.log(data);
    await axios
      .put(`/orders/verify-transaction`, data)
      .then((res) => {
        if (res.status === 200) {
          setIsVerified(true);
          axios
            .put(`/users/edit`, {
              available_sessions: user.available_sessions + order.galleriesQuantity,
            })
            .then((res) => {})
            .catch((err) => console.log(err));
        } else {
          setIsVerified(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const verificationProgress = () => {
    if (isVerified === undefined) {
      return <div className={styles.verifying}>Trwa weryfikacja transakcji...</div>;
    } else if (isVerified === false) {
      return <div className={styles.verificationInvalid}>Nie udało się zweryfikować transakcji.</div>;
    } else if (isVerified === true) {
      return <div className={styles.verificationCompleted}>Poprawnie zweryfikowano transakcję. </div>;
    }
  };

  return <div className={styles.container}>{verificationProgress()}</div>;
};

export default OrderReturnPage;
