import styled from "styled-components";
import { BsFillCameraFill } from "react-icons/bs";

const Camera = styled.div`
  height: 300px;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: heartbeat 1.5s ease-in-out infinite both;

  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
      transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
      transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }
`;

const LoadingBar = (props) => {
  return (
    <>
      <Camera>
        <BsFillCameraFill color="#191919" />
        <p>Wczytywanie...</p>
      </Camera>
    </>
  );
};

export default LoadingBar;
