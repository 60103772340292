import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./galleries.module.scss";
import axios from "axios";

import Page from "../../../components/layout/page/Page";
import GalleryList from "../../../components/galleries/galleryList/GalleryList";
import LoadingBar from "../../../components/layout/LoadingBar";
import CreateFirstGalleryImage from "../../../static/svg/CreateFirstGalleryImage";
import PrimaryButton from "../../../components/layout/primaryButton/PrimaryButton";
import GalleryFilter from "../../../components/galleries/galleryFilter/GalleryFilter";

const GalleriesPage = (props) => {
  const [query, setQuery] = useState("");
  const [galleries, setGalleries] = useState([]);
  const [filteredGalleries, setFilteredGalleries] = useState([]);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const getGalleries = async () => {
      setLoading(true);
      await axios.get("/galleries").then((res) => {
        setGalleries(res.data);
        setFilteredGalleries(res.data);
      });
      setLoading(false);
    };
    getGalleries();
  }, []);

  const filterGalleriesByStatus = (status) => {
    setFilteredGalleries([]);
    if (status === "all") {
      setFilteredGalleries(galleries);
    } else {
      setFilteredGalleries(galleries.filter((gallery) => gallery.status === status));
    }
  };

  const filterGalleriesByType = (type) => {
    setFilteredGalleries([]);
    if (type === "all") {
      setFilteredGalleries(galleries);
    } else {
      setFilteredGalleries(galleries.filter((gallery) => gallery.type === type));
    }
  };

  const search = (data) => {
    return data.filter(
      (gallery) =>
        gallery.title.toLowerCase().includes(query.toLowerCase()) ||
        gallery.customerName.toLowerCase().includes(query.toLowerCase())
    );
  };

  const gallery = () => {
    if (galleries.length === 0) {
      return (
        <div className={styles.createFirstGallery}>
          <CreateFirstGalleryImage />
          <h3 className={styles.createGalleryHeader}>Stwórz swoją pierwszą galerię i rozpocznij pracę</h3>
          <PrimaryButton
            onClick={() => navigate("/galleries/new-gallery")}
            title="Dodaj galerię"
            backgroundColor="#0099f0"
            border="none"
            color="#FFF"
          />
        </div>
      );
    } else {
      return <GalleryList galleries={search(filteredGalleries)} />;
    }
  };

  const statusValues = [
    { value: "new", valueName: "nowe" },
    { value: "sent", valueName: "wysłane" },
    { value: "selected", valueName: "wybrane" },
    { value: "edited", valueName: "gotowe" },
    { value: "done", valueName: "zakończone" },
  ];

  const typeValues = [
    { value: "rodzinna", valueName: "rodzinna" },
    { value: "dziecięca", valueName: "dziecięca" },
    { value: "noworodkowa", valueName: "noworodkowa" },
    { value: "ciążowa", valueName: "ciążowa" },
    { value: "reportaż", valueName: "reportaż" },
    { value: "inna", valueName: "inna" },
  ];
  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.headerTitle}>
          <h3>Twoje galerie</h3>
          <PrimaryButton
            onClick={() => {
              navigate("new-gallery");
            }}
            title="Dodaj galerię"
            backgroundColor="#0099F0"
            color="#FFF"
          />
        </div>
        <div className={styles.controls}>
          <input
            onChange={(e) => setQuery(e.target.value)}
            type="text"
            placeholder="wyszukaj galerie"
            className={styles.search}
          />
          <div className={styles.filters}>
            <GalleryFilter
              label="Status"
              title="wszystkie"
              firstValue="all"
              values={statusValues}
              onChange={(e) => filterGalleriesByStatus(e.target.value)}
            />
            <GalleryFilter
              label="Rodzaj sesji"
              title="wszystkie"
              firstValue="all"
              values={typeValues}
              onChange={(e) => filterGalleriesByType(e.target.value)}
            />
          </div>
        </div>
        {loading ? <LoadingBar /> : gallery()}
      </div>
    </Page>
  );
};

export default GalleriesPage;
