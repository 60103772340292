import { useRef, useState } from "react";
import CloseButton from "../../../../../static/svg/CloseButton";
import styles from "./resumeSettings.module.scss";
import axios from "axios";
import { useParams } from "react-router-dom";
import useCloseOutside from "../../../../../helpers/closeOutside";
import { IoCloseOutline } from "react-icons/io5";

const ResumeSettings = (props) => {
  const params = useParams();
  const ref = useRef(null);

  useCloseOutside(ref, props.close);

  const confirmPayment = async () => {
    const paid = props.gallery.price;

    const data = {
      paid: paid,
    };

    await axios
      .put(`/galleries/payment/${params.id}`, data)
      .then((res) => {
        props.getGallery();
      })
      .catch((error) => console.log(error));
  };

  const closeGallery = async () => {
    const data = {
      photos: props.photos,
      status: "done",
    };

    await axios
      .put(`/galleries/${params.id}`, data)
      .then((res) => {})
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.closeIcon} onClick={props.close}>
        <IoCloseOutline size="24" />
      </div>
      <ul className={styles.settingsList}>
        <li
          className={styles.setting}
          onClick={() => {
            props.close();
            props.confirmAction(
              `Czy potwierdzasz zapłatę? Po jej zatwierdzeniu nie będzie możliwości edycji.`,
              confirmPayment
            );
          }}
        >
          <p>Oznacz jako opłacona</p>
        </li>
        <li
          className={styles.setting}
          onClick={() => {
            props.close();
            props.confirmAction(
              `Czy na pewno chcesz zakończyć galerię? Po jej zakończeniu nie będzie możliwości edycji.`,
              closeGallery
            );
          }}
        >
          <p> Zakończ galerię</p>
        </li>
      </ul>
    </div>
  );
};

export default ResumeSettings;
