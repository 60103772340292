const ArrowBack = ({ background }) => {
  return (
    <svg
      style={{ cursor: "pointer", marginRight: "10px" }}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill={background ? background : "#e9e9e9"} />
      <path
        d="M21 29L13 20.5L21 12"
        stroke="#151414"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowBack;
