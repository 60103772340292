function CreateFirstGalleryImage({ color }) {
  return (
    <svg width="265" height="180" viewBox="0 0 265 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_369)">
        <path
          d="M250.567 179.291C251.187 172.594 252.9 166.045 255.638 159.905C256.402 158.196 257.241 156.525 258.158 154.891C258.204 154.806 258.215 154.706 258.189 154.613C258.163 154.519 258.102 154.439 258.018 154.391C257.934 154.342 257.835 154.327 257.741 154.35C257.647 154.373 257.565 154.432 257.514 154.514C254.18 160.463 251.844 166.922 250.6 173.631C250.254 175.504 249.994 177.391 249.82 179.291C249.776 179.77 250.523 179.767 250.567 179.291Z"
          fill="#F2F2F2"
        />
        <path
          d="M259.966 156.091C262.576 156.091 264.693 153.97 264.693 151.353C264.693 148.736 262.576 146.615 259.966 146.615C257.355 146.615 255.238 148.736 255.238 151.353C255.238 153.97 257.355 156.091 259.966 156.091Z"
          fill="#F2F2F2"
        />
        <path
          d="M251.05 179.417C251.452 175.072 252.564 170.823 254.34 166.84C254.835 165.732 255.38 164.647 255.975 163.588C256.005 163.532 256.012 163.467 255.995 163.407C255.978 163.346 255.938 163.294 255.884 163.262C255.83 163.231 255.765 163.221 255.704 163.236C255.643 163.251 255.59 163.289 255.557 163.343C253.394 167.202 251.879 171.392 251.072 175.745C250.847 176.96 250.679 178.184 250.566 179.417C250.537 179.728 251.021 179.726 251.05 179.417Z"
          fill="#F2F2F2"
        />
        <path
          d="M257.147 164.366C258.841 164.366 260.214 162.99 260.214 161.292C260.214 159.594 258.841 158.218 257.147 158.218C255.453 158.218 254.08 159.594 254.08 161.292C254.08 162.99 255.453 164.366 257.147 164.366Z"
          fill="#F2F2F2"
        />
        <path
          d="M250.438 179.127C248.089 175.453 246.359 171.418 245.315 167.182C245.025 166.002 244.789 164.812 244.608 163.609C244.598 163.547 244.564 163.492 244.513 163.454C244.462 163.417 244.399 163.401 244.337 163.409C244.275 163.418 244.218 163.45 244.179 163.499C244.14 163.549 244.122 163.611 244.128 163.674C244.791 168.051 246.168 172.288 248.202 176.218C248.771 177.315 249.389 178.384 250.056 179.425C250.224 179.688 250.605 179.388 250.438 179.127Z"
          fill="#F2F2F2"
        />
        <path
          d="M244.125 164.15C245.819 164.15 247.192 162.774 247.192 161.076C247.192 159.378 245.819 158.002 244.125 158.002C242.432 158.002 241.059 159.378 241.059 161.076C241.059 162.774 242.432 164.15 244.125 164.15Z"
          fill="#F2F2F2"
        />
        <path
          d="M52.5914 179.291C51.971 172.594 50.2576 166.045 47.5196 159.905C46.7565 158.196 45.9166 156.525 44.9999 154.891C44.9539 154.806 44.9428 154.706 44.969 154.612C44.9951 154.519 45.0565 154.439 45.1401 154.39C45.2237 154.341 45.323 154.327 45.4171 154.35C45.5112 154.373 45.5927 154.432 45.6444 154.514C48.9781 160.463 51.3137 166.922 52.5576 173.631C52.9036 175.504 53.1637 177.391 53.3378 179.291C53.382 179.77 52.6353 179.767 52.5914 179.291Z"
          fill="#F2F2F2"
        />
        <path
          d="M43.1921 156.091C45.8029 156.091 47.9193 153.97 47.9193 151.353C47.9193 148.736 45.8029 146.615 43.1921 146.615C40.5813 146.615 38.4648 148.736 38.4648 151.353C38.4648 153.97 40.5813 156.091 43.1921 156.091Z"
          fill="#F2F2F2"
        />
        <path
          d="M52.1084 179.417C51.706 175.072 50.5944 170.823 48.8181 166.84C48.3231 165.732 47.7782 164.647 47.1834 163.588C47.1535 163.532 47.1463 163.467 47.1633 163.407C47.1802 163.346 47.22 163.294 47.2743 163.262C47.3286 163.231 47.3931 163.221 47.4541 163.236C47.5152 163.251 47.5681 163.289 47.6016 163.343C49.7644 167.202 51.2795 171.392 52.0865 175.745C52.311 176.96 52.4797 178.184 52.5927 179.417C52.6214 179.728 52.1369 179.726 52.1084 179.417Z"
          fill="#F2F2F2"
        />
        <path
          d="M46.0101 164.366C47.7039 164.366 49.0769 162.99 49.0769 161.292C49.0769 159.594 47.7039 158.218 46.0101 158.218C44.3164 158.218 42.9434 159.594 42.9434 161.292C42.9434 162.99 44.3164 164.366 46.0101 164.366Z"
          fill="#F2F2F2"
        />
        <path
          d="M52.7192 179.127C55.0677 175.453 56.7984 171.418 57.8421 167.182C58.132 166.002 58.3676 164.812 58.5489 163.609C58.5594 163.547 58.5936 163.492 58.6441 163.454C58.6947 163.417 58.7578 163.401 58.8201 163.409C58.8824 163.418 58.9389 163.45 58.9779 163.499C59.0169 163.549 59.0352 163.611 59.0289 163.674C58.3658 168.051 56.9895 172.288 54.9551 176.218C54.3863 177.314 53.7683 178.384 53.101 179.425C52.9329 179.688 52.5521 179.388 52.7192 179.127Z"
          fill="#F2F2F2"
        />
        <path
          d="M59.0336 164.15C60.7273 164.15 62.1004 162.774 62.1004 161.076C62.1004 159.378 60.7273 158.002 59.0336 158.002C57.3398 158.002 55.9668 159.378 55.9668 161.076C55.9668 162.774 57.3398 164.15 59.0336 164.15Z"
          fill="#F2F2F2"
        />
        <path
          d="M232.798 117.347L232.872 173.059C232.872 174.093 232.464 175.085 231.735 175.818C231.006 176.55 230.018 176.963 228.986 176.965L205.898 176.999C212.398 164.812 216.078 145.63 216.056 130.844C216.05 125.009 215.459 119.19 214.291 113.473L228.901 113.452C229.933 113.451 230.922 113.861 231.653 114.591C232.384 115.322 232.796 116.313 232.798 117.347Z"
          fill="#0099F0"
        />
        <path
          d="M264.633 180H36.8058C36.7086 180 36.6154 179.961 36.5467 179.892C36.478 179.823 36.4395 179.729 36.4395 179.632C36.4395 179.534 36.478 179.441 36.5467 179.372C36.6154 179.303 36.7086 179.264 36.8058 179.263H264.633C264.73 179.263 264.824 179.302 264.893 179.371C264.962 179.44 265 179.534 265 179.632C265 179.729 264.962 179.823 264.893 179.892C264.824 179.961 264.73 180 264.633 180Z"
          fill="#3F3D56"
        />
        <path
          d="M198.965 109.732L157.818 109.571L161.819 96.2276C162.139 95.1794 162.785 94.2611 163.662 93.6071C164.54 92.9531 165.603 92.5978 166.696 92.593H166.716L190.201 92.6854C191.297 92.6946 192.362 93.0564 193.239 93.7176C194.115 94.3788 194.756 95.3046 195.068 96.3585L198.965 109.732Z"
          fill="#3F3D56"
        />
        <path
          d="M221.935 105.167C220.79 105.167 219.691 105.623 218.881 106.435C218.07 107.247 217.615 108.348 217.615 109.497H226.256C226.256 108.348 225.8 107.247 224.99 106.435C224.18 105.623 223.081 105.167 221.935 105.167Z"
          fill="#0099F0"
        />
        <path
          d="M235.206 179.711H121.646C120.256 179.709 118.922 179.155 117.939 178.169C116.955 177.183 116.402 175.847 116.4 174.453V114.446C116.402 113.052 116.955 111.715 117.939 110.729C118.922 109.744 120.256 109.189 121.646 109.188H235.206C236.597 109.189 237.93 109.744 238.914 110.729C239.897 111.715 240.45 113.052 240.452 114.446V174.453C240.45 175.847 239.897 177.183 238.914 178.169C237.93 179.155 236.597 179.709 235.206 179.711ZM121.646 109.806C120.419 109.808 119.243 110.297 118.375 111.167C117.507 112.036 117.019 113.216 117.018 114.446V174.453C117.019 175.683 117.507 176.862 118.375 177.732C119.243 178.602 120.419 179.091 121.646 179.092H235.206C236.433 179.091 237.61 178.602 238.477 177.732C239.345 176.862 239.833 175.683 239.835 174.453V114.446C239.833 113.216 239.345 112.036 238.477 111.167C237.61 110.297 236.433 109.808 235.206 109.806H121.646Z"
          fill="#3F3D56"
        />
        <path
          d="M183.363 104.702H173.488C172.793 104.702 172.125 104.425 171.633 103.932C171.142 103.439 170.865 102.771 170.865 102.073C170.865 101.376 171.142 100.707 171.633 100.214C172.125 99.7211 172.793 99.4441 173.488 99.4441H183.363C184.059 99.4441 184.726 99.7211 185.218 100.214C185.71 100.707 185.986 101.376 185.986 102.073C185.986 102.771 185.71 103.439 185.218 103.932C184.726 104.425 184.059 104.702 183.363 104.702Z"
          fill="white"
        />
        <path
          d="M178.425 167.957C173.787 167.957 169.252 166.578 165.396 163.995C161.539 161.412 158.533 157.741 156.758 153.445C154.983 149.15 154.518 144.423 155.423 139.863C156.328 135.303 158.562 131.114 161.842 127.827C165.122 124.539 169.3 122.3 173.85 121.393C178.399 120.486 183.115 120.952 187.4 122.731C191.685 124.51 195.348 127.523 197.925 131.389C200.502 135.255 201.878 139.8 201.878 144.449C201.871 150.682 199.398 156.657 195.001 161.064C190.604 165.471 184.643 167.95 178.425 167.957ZM178.425 121.56C173.909 121.56 169.494 122.902 165.739 125.418C161.983 127.933 159.056 131.507 157.328 135.69C155.6 139.872 155.148 144.475 156.029 148.915C156.91 153.355 159.085 157.433 162.278 160.634C165.472 163.835 169.541 166.015 173.97 166.899C178.4 167.782 182.991 167.328 187.164 165.596C191.337 163.864 194.903 160.93 197.412 157.166C199.921 153.402 201.261 148.976 201.261 144.449C201.254 138.381 198.846 132.563 194.565 128.272C190.284 123.981 184.479 121.567 178.425 121.56Z"
          fill="#3F3D56"
        />
        <path
          d="M178.425 159.915C175.374 159.915 172.391 159.008 169.853 157.309C167.316 155.609 165.338 153.194 164.171 150.368C163.003 147.542 162.697 144.432 163.293 141.432C163.888 138.432 165.357 135.676 167.515 133.513C169.673 131.351 172.422 129.878 175.415 129.281C178.408 128.684 181.511 128.99 184.33 130.161C187.149 131.331 189.559 133.314 191.254 135.857C192.95 138.4 193.855 141.39 193.855 144.449C193.85 148.55 192.223 152.481 189.33 155.38C186.438 158.279 182.516 159.91 178.425 159.915ZM178.425 129.602C175.496 129.602 172.632 130.473 170.196 132.104C167.76 133.736 165.862 136.055 164.741 138.768C163.62 141.481 163.326 144.466 163.898 147.346C164.469 150.226 165.88 152.871 167.952 154.948C170.023 157.024 172.662 158.438 175.536 159.011C178.409 159.584 181.387 159.29 184.094 158.166C186.8 157.042 189.114 155.139 190.741 152.698C192.369 150.256 193.238 147.386 193.238 144.449C193.233 140.513 191.671 136.739 188.894 133.956C186.117 131.172 182.352 129.607 178.425 129.602Z"
          fill="#3F3D56"
        />
        <path
          d="M178.425 156.203C184.902 156.203 190.152 150.941 190.152 144.449C190.152 137.958 184.902 132.695 178.425 132.695C171.949 132.695 166.699 137.958 166.699 144.449C166.699 150.941 171.949 156.203 178.425 156.203Z"
          fill="#CCCCCC"
        />
        <path
          d="M171.328 144.449C171.329 142.586 172 140.786 173.219 139.379C174.438 137.972 176.122 137.054 177.963 136.793C176.869 136.637 175.754 136.719 174.695 137.032C173.635 137.345 172.655 137.883 171.82 138.609C170.986 139.335 170.317 140.232 169.858 141.24C169.399 142.247 169.162 143.342 169.162 144.449C169.162 145.557 169.399 146.651 169.858 147.659C170.317 148.666 170.986 149.563 171.82 150.289C172.655 151.015 173.635 151.553 174.695 151.866C175.754 152.179 176.869 152.261 177.963 152.105C176.122 151.844 174.438 150.926 173.219 149.519C172 148.113 171.329 146.312 171.328 144.449Z"
          fill="white"
        />
        <path
          d="M229.65 109.806H214.221V109.497C214.221 107.446 215.033 105.479 216.48 104.029C217.927 102.579 219.889 101.764 221.935 101.764C223.981 101.764 225.944 102.579 227.39 104.029C228.837 105.479 229.65 107.446 229.65 109.497V109.806ZM214.845 109.188H229.026C228.947 107.356 228.165 105.626 226.844 104.358C225.523 103.091 223.764 102.383 221.935 102.383C220.106 102.383 218.348 103.091 217.027 104.358C215.706 105.626 214.924 107.356 214.845 109.188Z"
          fill="#3F3D56"
        />
        <path
          d="M124.115 118.467C123.505 118.467 122.908 118.286 122.401 117.946C121.893 117.606 121.498 117.123 121.264 116.558C121.031 115.992 120.97 115.37 121.089 114.77C121.208 114.17 121.502 113.619 121.933 113.187C122.365 112.754 122.915 112.46 123.513 112.34C124.112 112.221 124.732 112.282 125.296 112.516C125.86 112.75 126.342 113.147 126.681 113.655C127.02 114.164 127.201 114.762 127.201 115.374C127.2 116.194 126.875 116.98 126.296 117.56C125.718 118.14 124.933 118.466 124.115 118.467ZM124.115 112.899C123.627 112.899 123.15 113.045 122.744 113.316C122.338 113.588 122.021 113.975 121.834 114.427C121.648 114.879 121.599 115.377 121.694 115.857C121.789 116.337 122.024 116.778 122.37 117.124C122.715 117.47 123.155 117.705 123.634 117.801C124.112 117.896 124.609 117.847 125.06 117.66C125.511 117.473 125.897 117.156 126.168 116.749C126.439 116.342 126.584 115.863 126.584 115.374C126.583 114.718 126.323 114.089 125.86 113.625C125.397 113.161 124.77 112.9 124.115 112.899Z"
          fill="#3F3D56"
        />
        <path
          d="M124.115 116.92C124.967 116.92 125.658 116.228 125.658 115.374C125.658 114.52 124.967 113.827 124.115 113.827C123.263 113.827 122.572 114.52 122.572 115.374C122.572 116.228 123.263 116.92 124.115 116.92Z"
          fill="#0099F0"
        />
        <path
          d="M132.139 116.92C132.991 116.92 133.682 116.228 133.682 115.374C133.682 114.52 132.991 113.827 132.139 113.827C131.286 113.827 130.596 114.52 130.596 115.374C130.596 116.228 131.286 116.92 132.139 116.92Z"
          fill="#CCCCCC"
        />
        <path
          d="M140.16 116.92C141.012 116.92 141.703 116.228 141.703 115.374C141.703 114.52 141.012 113.827 140.16 113.827C139.308 113.827 138.617 114.52 138.617 115.374C138.617 116.228 139.308 116.92 140.16 116.92Z"
          fill="#CCCCCC"
        />
        <path
          d="M132.139 118.467C131.528 118.467 130.932 118.286 130.424 117.946C129.917 117.606 129.521 117.123 129.288 116.558C129.054 115.992 128.993 115.37 129.112 114.77C129.231 114.17 129.525 113.619 129.957 113.187C130.388 112.754 130.938 112.46 131.537 112.34C132.135 112.221 132.756 112.282 133.319 112.516C133.883 112.75 134.365 113.147 134.704 113.655C135.043 114.164 135.224 114.762 135.224 115.374C135.224 116.194 134.898 116.98 134.32 117.56C133.741 118.14 132.957 118.466 132.139 118.467ZM132.139 112.899C131.65 112.899 131.173 113.045 130.767 113.316C130.361 113.588 130.045 113.975 129.858 114.427C129.671 114.879 129.622 115.377 129.717 115.857C129.813 116.337 130.048 116.778 130.393 117.124C130.738 117.47 131.178 117.705 131.657 117.801C132.136 117.896 132.632 117.847 133.083 117.66C133.534 117.473 133.92 117.156 134.191 116.749C134.462 116.342 134.607 115.863 134.607 115.374C134.607 114.718 134.346 114.089 133.883 113.625C133.421 113.161 132.793 112.9 132.139 112.899Z"
          fill="#3F3D56"
        />
        <path
          d="M140.16 118.467C139.55 118.467 138.953 118.286 138.446 117.946C137.938 117.606 137.543 117.123 137.309 116.558C137.076 115.992 137.014 115.37 137.134 114.77C137.253 114.17 137.546 113.619 137.978 113.187C138.41 112.754 138.959 112.46 139.558 112.34C140.157 112.221 140.777 112.282 141.341 112.516C141.905 112.75 142.387 113.147 142.726 113.655C143.065 114.164 143.246 114.762 143.246 115.374C143.245 116.194 142.92 116.98 142.341 117.56C141.763 118.14 140.978 118.466 140.16 118.467ZM140.16 112.899C139.672 112.899 139.195 113.045 138.789 113.316C138.383 113.588 138.066 113.975 137.879 114.427C137.692 114.879 137.644 115.377 137.739 115.857C137.834 116.337 138.069 116.778 138.414 117.124C138.76 117.47 139.2 117.705 139.678 117.801C140.157 117.896 140.654 117.847 141.105 117.66C141.556 117.473 141.941 117.156 142.213 116.749C142.484 116.342 142.629 115.863 142.629 115.374C142.628 114.718 142.368 114.089 141.905 113.625C141.442 113.161 140.815 112.9 140.16 112.899Z"
          fill="#3F3D56"
        />
        <path d="M156.825 136.059H116.709V136.677H156.825V136.059Z" fill="#3F3D56" />
        <path d="M155.829 148.509H116.709V149.128H155.829V148.509Z" fill="#3F3D56" />
        <path d="M240.144 136.059H200.027V136.677H240.144V136.059Z" fill="#3F3D56" />
        <path d="M240.073 148.509H200.953V149.128H240.073V148.509Z" fill="#3F3D56" />
        <path
          d="M84.1904 75.0011C81.6879 73.8488 80.0429 70.5541 80.1366 67.305C80.2303 64.0558 81.9361 61.017 84.2889 59.4675C85.4358 58.7309 86.7474 58.2914 88.1058 58.1885C89.4642 58.0856 90.8268 58.3226 92.0712 58.8781C94.5962 59.9554 97.9864 60.2489 99.5041 62.9267C100.67 64.9845 100.25 69.5753 99.4245 71.8635C98.7108 73.8412 96.9656 74.9555 95.2638 75.5791C93.3801 76.2632 91.3718 76.5337 89.3748 76.3725C87.3778 76.2112 85.4386 75.6219 83.6884 74.6445"
          fill="#2F2E41"
        />
        <path d="M68.9318 175.909L65.334 174.735L68.1363 160.268L73.4461 161.999L68.9318 175.909Z" fill="#FFB8B8" />
        <path
          d="M63.099 172.867L70.0372 175.129L68.616 179.508L57.3093 175.821C57.4959 175.246 57.7937 174.714 58.1857 174.254C58.5776 173.794 59.0561 173.416 59.5937 173.142C60.1313 172.868 60.7176 172.702 61.319 172.655C61.9205 172.608 62.5253 172.68 63.099 172.867Z"
          fill="#2F2E41"
        />
        <path d="M101.14 175.987L97.3563 175.987L95.5566 161.36L101.14 161.36L101.14 175.987Z" fill="#FFB8B8" />
        <path
          d="M94.6525 174.903H101.949V179.508H90.0586C90.0586 178.903 90.1774 178.305 90.4083 177.746C90.6391 177.187 90.9775 176.68 91.4041 176.252C91.8307 175.824 92.3371 175.485 92.8945 175.254C93.4518 175.022 94.0492 174.903 94.6525 174.903Z"
          fill="#2F2E41"
        />
        <path
          d="M100.948 170.005C100.925 170.005 100.902 170.004 100.879 170.003L96.6871 169.802C96.3341 169.785 96.001 169.634 95.7553 169.379C95.5097 169.124 95.37 168.786 95.3647 168.431L94.7966 126.863C94.7951 126.759 94.7595 126.66 94.6952 126.579C94.631 126.499 94.5418 126.442 94.4418 126.418C94.3418 126.394 94.2366 126.404 94.1429 126.446C94.0492 126.489 93.9723 126.561 93.9243 126.653L71.7189 168.855C71.5811 169.12 71.3619 169.333 71.0938 169.462C70.8256 169.591 70.523 169.63 70.2309 169.573L66.0585 168.777C65.862 168.739 65.6759 168.66 65.513 168.544C65.35 168.427 65.214 168.277 65.1143 168.103C65.0147 167.929 64.9536 167.736 64.9354 167.536C64.9172 167.337 64.9423 167.135 65.0089 166.946L81.188 120.997L82.433 107.405C82.4607 107.088 82.5967 106.79 82.8179 106.562C83.0392 106.334 83.3323 106.189 83.6476 106.152C88.3619 105.572 94.9492 101.7 97.5625 100.062C97.7229 99.9609 97.9025 99.8939 98.09 99.8657C98.2775 99.8374 98.4689 99.8484 98.6519 99.8979C98.8371 99.9478 99.0101 100.035 99.1603 100.155C99.3105 100.274 99.4348 100.423 99.5255 100.592C105.821 112.319 108.228 125.75 106.4 138.94L102.321 168.801C102.277 169.135 102.114 169.441 101.861 169.662C101.608 169.884 101.284 170.006 100.948 170.005Z"
          fill="#2F2E41"
        />
        <path
          d="M89.6632 78.1939C93.849 78.1939 97.2424 74.7925 97.2424 70.5968C97.2424 66.4011 93.849 62.9998 89.6632 62.9998C85.4773 62.9998 82.084 66.4011 82.084 70.5968C82.084 74.7925 85.4773 78.1939 89.6632 78.1939Z"
          fill="#FFB8B8"
        />
        <path
          d="M82.1119 110.669L79.8298 94.3231C79.2742 91.8394 79.7252 89.2362 81.0835 87.0856C82.4419 84.935 84.5965 83.413 87.0739 82.8541C89.5513 82.2951 92.1487 82.7449 94.2954 84.1047C96.442 85.4645 97.9623 87.6229 98.522 90.1057C99.1719 93.1272 100.492 96.8054 100.01 100.771C96.6301 110.174 90.4945 113.073 82.1119 110.669Z"
          fill="#B3B3B3"
        />
        <path
          d="M92.1404 85.305H86.5859C86.2177 85.3046 85.8648 85.1578 85.6044 84.8968C85.3441 84.6359 85.1977 84.2821 85.1973 83.9131V82.0572C85.1977 81.6882 85.3441 81.3344 85.6044 81.0734C85.8648 80.8125 86.2177 80.6657 86.5859 80.6653H92.1404C92.5086 80.6657 92.8616 80.8125 93.1219 81.0734C93.3822 81.3344 93.5287 81.6882 93.5291 82.0572V83.9131C93.5287 84.2821 93.3822 84.6359 93.1219 84.8968C92.8616 85.1578 92.5086 85.3046 92.1404 85.305Z"
          fill="#B3B3B3"
        />
        <path
          d="M120.291 116.2C120.442 115.738 120.49 115.249 120.432 114.767C120.373 114.284 120.21 113.821 119.954 113.409C119.697 112.996 119.354 112.645 118.948 112.381C118.541 112.116 118.082 111.944 117.602 111.876L100.312 87.0647L95.1855 92.1451L113.793 114.936C113.733 115.752 113.974 116.561 114.469 117.211C114.965 117.861 115.681 118.306 116.482 118.461C117.283 118.617 118.114 118.473 118.816 118.056C119.518 117.639 120.043 116.979 120.291 116.2Z"
          fill="#FFB8B8"
        />
        <path
          d="M105.722 94.6549L99.478 98.8329C99.3004 98.9518 99.0993 99.0309 98.8884 99.0648C98.6775 99.0987 98.4617 99.0867 98.2559 99.0295C98.05 98.9724 97.8589 98.8714 97.6955 98.7335C97.5321 98.5956 97.4002 98.4241 97.3089 98.2305L94.1106 91.4479C93.5095 90.5334 93.2946 89.4173 93.513 88.3443C93.7315 87.2712 94.3654 86.3287 95.2759 85.7234C96.1863 85.118 97.2991 84.8991 98.3703 85.1147C99.4415 85.3302 100.384 85.9627 100.991 86.8734L105.999 92.416C106.142 92.5747 106.249 92.7625 106.314 92.9667C106.378 93.1708 106.398 93.3865 106.372 93.599C106.345 93.8115 106.274 94.0158 106.162 94.198C106.049 94.3802 105.899 94.5361 105.722 94.6549Z"
          fill="#B3B3B3"
        />
        <path
          d="M59.2113 82.8621C59.2492 83.029 59.2739 83.1987 59.2851 83.3695L72.5412 91.0349L75.7633 89.1756L79.1982 93.683L73.8133 97.5301C73.3782 97.8409 72.8544 98.0018 72.3203 97.9888C71.7862 97.9758 71.2708 97.7894 70.8514 97.4578L57.1821 86.6486C56.5652 86.8774 55.8934 86.9127 55.2561 86.7495C54.6187 86.5863 54.0461 86.2326 53.6142 85.7352C53.1824 85.2379 52.9118 84.6205 52.8385 83.9653C52.7652 83.31 52.8927 82.6479 53.204 82.0671C53.5153 81.4862 53.9956 81.0141 54.5811 80.7136C55.1666 80.4131 55.8295 80.2983 56.4816 80.3846C57.1338 80.4709 57.7443 80.7542 58.2319 81.1968C58.7195 81.6393 59.0611 82.2202 59.2113 82.8621Z"
          fill="#FFB8B8"
        />
        <path
          d="M76.3953 96.6047L73.0645 89.861C72.9697 89.6691 72.9173 89.459 72.9108 89.245C72.9044 89.031 72.944 88.8181 73.027 88.6208C73.1101 88.4236 73.2345 88.2465 73.3919 88.1018C73.5494 87.9571 73.7361 87.8481 73.9393 87.7823L81.061 85.4749C82.0431 84.9944 83.1753 84.924 84.2092 85.2789C85.2431 85.6339 86.0944 86.3853 86.5764 87.3685C87.0584 88.3516 87.1318 89.4862 86.7805 90.5235C86.4292 91.5608 85.6819 92.4162 84.7024 92.902L78.5749 97.1676C78.3994 97.2897 78.1998 97.3724 77.9895 97.4102C77.7793 97.4479 77.5634 97.4398 77.3565 97.3864C77.1497 97.333 76.9567 97.2355 76.7909 97.1006C76.625 96.9657 76.4901 96.7965 76.3953 96.6047Z"
          fill="#B3B3B3"
        />
        <path
          d="M94.7492 61.0286C95.0221 61.0686 95.3005 61.0172 95.5414 60.8824C95.7822 60.7477 95.972 60.5371 96.0813 60.2834C96.1824 60.0262 96.2234 59.7493 96.2012 59.4739C96.179 59.1984 96.0941 58.9317 95.9531 58.6942C95.6661 58.221 95.2655 57.8272 94.7879 57.5487C94.2378 57.1802 93.6019 56.9609 92.9421 56.9122C92.6128 56.8905 92.2828 56.9401 91.9743 57.0574C91.6658 57.1748 91.3861 57.3573 91.1542 57.5924C90.9278 57.8334 90.7784 58.1367 90.725 58.4633C90.6716 58.7899 90.7166 59.1251 90.8543 59.4259C91.1314 59.9616 91.7129 60.262 92.2795 60.466C93.4035 60.8696 94.6007 61.0272 95.7906 60.9282"
          fill="#2F2E41"
        />
        <path
          d="M97.1744 74.0555C95.8497 74.1179 96.0778 71.3004 95.6632 70.0377C95.2486 68.775 94.6771 67.2689 93.3731 67.0268C92.3045 66.8284 91.3388 67.6117 90.4079 68.174C88.7488 69.1761 86.6013 69.5458 84.8592 68.6969C83.117 67.848 82.0576 65.5796 82.9049 63.8336C83.1629 63.3536 83.5165 62.9319 83.9438 62.5945C84.371 62.2571 84.8628 62.0112 85.3887 61.8721C86.4431 61.6005 87.5417 61.5476 88.6172 61.7166C91.0164 62.0115 93.3219 62.8306 95.3708 64.1161C96.7847 65.0049 98.1301 66.2152 98.551 67.834C98.972 69.4529 99.1435 73.8549 97.4978 74.1336L97.1744 74.0555Z"
          fill="#2F2E41"
        />
        <path
          d="M93.7069 57.1099C92.7063 55.8384 93.0832 53.835 94.2495 52.7142C95.4158 51.5934 97.15 51.2441 98.7595 51.3866C100.354 51.5476 101.878 52.126 103.18 53.0639C104.481 54.0017 105.513 55.266 106.173 56.73C107.19 59.062 107.182 61.7294 108.102 64.1016C108.592 65.3662 109.335 66.5168 110.286 67.4825C111.237 68.4482 112.374 69.2087 113.629 69.717C114.884 70.2253 116.23 70.4708 117.583 70.4382C118.936 70.4057 120.268 70.0958 121.497 69.5278C123.098 68.7879 124.623 67.339 124.531 65.5742C125.544 69.4859 123.72 73.9605 120.266 76.0436C118.242 77.2638 115.846 77.678 113.502 77.9631C111.974 78.1488 110.404 78.287 108.913 77.9043C106.827 77.3689 105.081 75.8258 104.023 73.9459C102.966 72.0659 102.55 69.8751 102.534 67.717C102.521 66.0358 102.741 64.3537 102.625 62.6765C102.509 60.9992 102.006 59.2644 100.793 58.1032C99.58 56.9419 97.5331 56.5597 96.2019 57.5825C95.6669 57.9936 95.2749 58.5939 94.6725 58.8972C94.0701 59.2004 93.1184 58.9646 93.0781 58.2901L93.7069 57.1099Z"
          fill="#2F2E41"
        />
        <path
          d="M64.6681 82.9165H2.1556C1.5841 82.9158 1.0362 82.688 0.632094 82.2829C0.227983 81.8778 0.000663165 81.3286 0 80.7558V45.4646C0.00066398 44.8918 0.227984 44.3426 0.632094 43.9375C1.0362 43.5325 1.5841 43.3046 2.1556 43.304H64.6681C65.2396 43.3046 65.7875 43.5325 66.1916 43.9375C66.5957 44.3426 66.823 44.8918 66.8237 45.4646V80.7558C66.823 81.3286 66.5957 81.8778 66.1916 82.2829C65.7875 82.688 65.2396 82.9158 64.6681 82.9165ZM2.1556 43.7841C1.7111 43.7846 1.28494 43.9618 0.970631 44.2769C0.656319 44.5919 0.479519 45.0191 0.47902 45.4646V80.7558C0.479519 81.2013 0.656319 81.6285 0.970631 81.9436C1.28494 82.2586 1.7111 82.4358 2.1556 82.4363H64.6681C65.1126 82.4358 65.5387 82.2586 65.853 81.9435C66.1673 81.6285 66.3441 81.2013 66.3447 80.7558V45.4646C66.3441 45.0191 66.1673 44.5919 65.853 44.2769C65.5387 43.9619 65.1126 43.7846 64.6681 43.7841H2.1556Z"
          fill="#3F3D56"
        />
        <path
          d="M22.7631 68.0773C27.6999 68.0773 31.702 64.0658 31.702 59.1174C31.702 54.169 27.6999 50.1575 22.7631 50.1575C17.8263 50.1575 13.8242 54.169 13.8242 59.1174C13.8242 64.0658 17.8263 68.0773 22.7631 68.0773Z"
          fill="#E6E6E6"
        />
        <path
          d="M57.2083 74.9392L46.7154 56.7222C46.6498 56.6083 46.5555 56.5138 46.4419 56.448C46.3283 56.3823 46.1994 56.3477 46.0683 56.3477C45.9371 56.3477 45.8082 56.3823 45.6946 56.448C45.581 56.5138 45.4867 56.6083 45.4211 56.7222L39.0177 67.8395L29.4714 51.2658C29.3873 51.1198 29.2663 50.9986 29.1207 50.9143C28.9751 50.83 28.8099 50.7856 28.6417 50.7856C28.4735 50.7856 28.3083 50.83 28.1627 50.9143C28.017 50.9986 27.8961 51.1198 27.812 51.2658L14.3588 74.6222C14.2747 74.7682 14.2305 74.9338 14.2305 75.1024C14.2305 75.271 14.2747 75.4366 14.3588 75.5825C14.4429 75.7285 14.5638 75.8497 14.7095 75.934C14.8551 76.0183 15.0203 76.0627 15.1885 76.0627H56.5611C56.6923 76.0627 56.8211 76.0281 56.9347 75.9624C57.0483 75.8966 57.1427 75.8021 57.2082 75.6882C57.2738 75.5743 57.3084 75.4452 57.3084 75.3137C57.3084 75.1822 57.2738 75.0531 57.2083 74.9392Z"
          fill="#0099F0"
        />
        <path d="M8.38296 47.6252H4.55078V51.4665H8.38296V47.6252Z" fill="#3F3D56" />
        <path
          d="M89.0019 30.9517H40.157C39.7104 30.9512 39.2823 30.7732 38.9666 30.4567C38.6508 30.1402 38.4732 29.7111 38.4727 29.2635V1.68828C38.4732 1.24068 38.6508 0.811564 38.9666 0.495063C39.2823 0.178563 39.7104 0.000520648 40.157 0H89.0019C89.4484 0.000504307 89.8765 0.17854 90.1923 0.495045C90.5081 0.811549 90.6857 1.24068 90.6862 1.68828V29.2635C90.6857 29.7111 90.5081 30.1402 90.1923 30.4567C89.8766 30.7732 89.4484 30.9512 89.0019 30.9517ZM40.157 0.375172C39.8096 0.375564 39.4767 0.514034 39.2311 0.760205C38.9855 1.00637 38.8473 1.34014 38.8469 1.68828V29.2635C38.8473 29.6116 38.9855 29.9454 39.2311 30.1915C39.4767 30.4377 39.8096 30.5762 40.157 30.5766H89.0019C89.3492 30.5762 89.6822 30.4377 89.9277 30.1915C90.1733 29.9454 90.3115 29.6116 90.3119 29.2635V1.68828C90.3115 1.34015 90.1733 1.00639 89.9277 0.760223C89.6822 0.514056 89.3492 0.375579 89.0019 0.375172H40.157Z"
          fill="#CCCCCC"
        />
        <path
          d="M56.2599 19.3571C60.1173 19.3571 63.2444 16.2227 63.2444 12.3562C63.2444 8.48966 60.1173 5.35522 56.2599 5.35522C52.4025 5.35522 49.2754 8.48966 49.2754 12.3562C49.2754 16.2227 52.4025 19.3571 56.2599 19.3571Z"
          fill="#F2F2F2"
        />
        <path
          d="M83.173 24.7186L74.9743 10.4845C74.9231 10.3955 74.8493 10.3217 74.7606 10.2703C74.6718 10.2189 74.5711 10.1919 74.4687 10.1919C74.3662 10.1919 74.2655 10.2189 74.1767 10.2703C74.088 10.3217 74.0143 10.3955 73.963 10.4845L68.9596 19.1711L61.5005 6.22111C61.4348 6.10705 61.3403 6.01233 61.2265 5.94647C61.1127 5.88062 60.9836 5.84595 60.8522 5.84595C60.7208 5.84595 60.5917 5.88062 60.4779 5.94647C60.3641 6.01233 60.2696 6.10705 60.2039 6.22111L49.6921 24.471C49.6264 24.585 49.5918 24.7144 49.5918 24.8461C49.5918 24.9779 49.6264 25.1073 49.6921 25.2213C49.7578 25.3354 49.8523 25.4301 49.9661 25.496C50.0799 25.5618 50.209 25.5965 50.3404 25.5965H82.6674C82.7699 25.5965 82.8706 25.5694 82.9593 25.5181C83.0481 25.4667 83.1218 25.3928 83.173 25.3039C83.2243 25.2149 83.2513 25.114 83.2513 25.0113C83.2513 24.9085 83.2243 24.8076 83.173 24.7186Z"
          fill="#E6E6E6"
        />
        <path d="M45.0217 3.37646H42.0273V6.37785H45.0217V3.37646Z" fill="#CCCCCC" />
      </g>
      <defs>
        <clipPath id="clip0_120_369">
          <rect width="265" height="180" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CreateFirstGalleryImage;
