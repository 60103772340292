function QuestionMarkIcon({ color }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#E8E8E8" />
      <path
        d="M9.038 11.598C9.038 11.2713 9.08933 10.982 9.192 10.73C9.304 10.478 9.43933 10.254 9.598 10.058C9.766 9.862 9.94333 9.68 10.13 9.512C10.3167 9.344 10.4893 9.18533 10.648 9.036C10.816 8.87733 10.9513 8.714 11.054 8.546C11.166 8.378 11.222 8.19133 11.222 7.986C11.222 7.65 11.082 7.37933 10.802 7.174C10.5313 6.96867 10.1673 6.866 9.71 6.866C9.27133 6.866 8.87933 6.95933 8.534 7.146C8.18867 7.32333 7.904 7.57533 7.68 7.902L5.986 6.908C6.35933 6.33867 6.87733 5.886 7.54 5.55C8.20267 5.20467 9.00533 5.032 9.948 5.032C10.648 5.032 11.264 5.13467 11.796 5.34C12.328 5.536 12.7433 5.82533 13.042 6.208C13.35 6.59067 13.504 7.062 13.504 7.622C13.504 7.986 13.448 8.308 13.336 8.588C13.224 8.868 13.0793 9.11067 12.902 9.316C12.7247 9.52133 12.5333 9.71267 12.328 9.89C12.132 10.0673 11.9453 10.24 11.768 10.408C11.5907 10.576 11.4413 10.7533 11.32 10.94C11.208 11.1267 11.152 11.346 11.152 11.598H9.038ZM10.102 15.112C9.71 15.112 9.388 14.986 9.136 14.734C8.884 14.482 8.758 14.1833 8.758 13.838C8.758 13.4833 8.884 13.1893 9.136 12.956C9.388 12.7133 9.71 12.592 10.102 12.592C10.5033 12.592 10.8253 12.7133 11.068 12.956C11.32 13.1893 11.446 13.4833 11.446 13.838C11.446 14.1833 11.32 14.482 11.068 14.734C10.8253 14.986 10.5033 15.112 10.102 15.112Z"
        fill="#151414"
      />
    </svg>
  );
}

export default QuestionMarkIcon;
