import { useState } from "react";
import styles from "./formSelect.module.scss";

const FormSelect = (props) => {
  const [focused, setFocused] = useState(false);
  const { values, label, errorMessage, onChange, id, initialOption, isRequired, icon, ...selectProps } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <div className={styles.selectContainer}>
      <label>
        {label} {isRequired ? <p className={styles.dot}>*</p> : ""}
      </label>
      <div className={styles.select}>
        {icon ? <div className={styles.icon}>{icon}</div> : ""}
        <select
          style={icon ? { paddingLeft: "40px" } : {}}
          {...selectProps}
          onChange={onChange}
          onBlur={handleFocus}
          focused={focused.toString()}
        >
          {props.firstgroup ? (
            <>
              <optgroup label={props.firstgroup}>
                <option value={initialOption}>{props.selection}</option>
              </optgroup>
              <optgroup label={props.secondgroup}>
                {values.map((value) => {
                  return (
                    <option value={value._id} key={value._id}>
                      {value.name}
                    </option>
                  );
                })}
              </optgroup>
            </>
          ) : (
            <>
              <option value={initialOption}>{props.selection}</option>
              {values.map((value) => {
                return (
                  <option value={value._id} key={value._id}>
                    {value.name}
                  </option>
                );
              })}
            </>
          )}
        </select>
        <span className={styles.errorMessage}>{errorMessage}</span>
      </div>
    </div>
  );
};

export default FormSelect;
