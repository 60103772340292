import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import Page from "../../../components/layout/page/Page";
import GalleriesList from "../../../components/galleries/galleryList/GalleryList";
import LoadingBar from "../../../components/layout/LoadingBar";
import ArrowBack from "../../../static/svg/ArrowBack";

const Wrapper = styled.section`
  .empty-list {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e8e8e8;
    height: 100px;
  }
`;
const TitleHeader = styled.div`
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const CustomerGalleriesPage = () => {
  const [galleries, setGalleries] = useState([]);
  const [loading, setLoading] = useState(false);

  let params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    getCustomerGalleries();
  }, []);

  const getCustomerGalleries = async () => {
    setLoading(true);
    await axios.get(`/customers/galleries/${params.id}`).then((res) => {
      setGalleries(res.data);
    });
    setLoading(false);
  };
  return (
    <Page>
      <Wrapper>
        <TitleHeader>
          <div onClick={() => navigate(`/customers/${params.id}`)} className="arrow-back">
            <ArrowBack />
          </div>
          <div>
            <h3>Lista galerii klienta</h3>
            <p>Tutaj znajdziesz listę wszystkich galerii utworzonych dla danego klienta</p>
          </div>
        </TitleHeader>
        {galleries.length ? (
          <GalleriesList galleries={galleries} />
        ) : (
          <div className="empty-list">
            <p>Klient nie posiada galerii</p>
          </div>
        )}
      </Wrapper>
    </Page>
  );
};

export default CustomerGalleriesPage;
