import { Link, useLocation } from "react-router-dom";
import Logo from "../../static/svg/Logo";
import styled from "styled-components";

const NavigationWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 3vw;
  height: 50px;
  background: #fff;
  li {
    cursor: pointer;
    padding: 0 15px;
    background: #0096ea;
    border-radius: 2px;
    height: 30px;
    a {
      font-weight: 700;
      font-size: 14px;
      line-height: 30px;

      color: #fff;
    }
  }
`;

const Navbar = () => {
  const location = useLocation();

  const actionButton = () => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/reset-password" ||
      location.pathname === "/privacy-policy"
    ) {
      return (
        <li>
          <Link to="/registration">Załóż konto</Link>
        </li>
      );
    } else if (location.pathname === "/registration") {
      return (
        <li>
          <Link to="/login">Zaloguj się</Link>
        </li>
      );
    }
  };

  return (
    <NavigationWrapper>
      <Link to="/dashboard">
        <Logo />
      </Link>

      <ul>{actionButton()}</ul>
    </NavigationWrapper>
  );
};

export default Navbar;
