import styles from "./galleryFilter.module.scss";

const GalleryFilter = ({ values, title, onChange, firstValue, label }) => {
  return (
    <div className={styles.container}>
      <label htmlFor="filter">{label}</label>
      <select onChange={onChange} name="filter" id="filter">
        <option value={firstValue}>{title}</option>
        {values.map((item) => (
          <option value={item.value} key={item.value}>
            {item.valueName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default GalleryFilter;
