import axios from "axios";
import ArrowBack from "../../../static/svg/ArrowBack";
import styles from "./orderConfirm.module.scss";
import ReturnButton from "../../../components/layout/returnButton/ReturnButton";

const OrderConfirm = ({ close, order }) => {
  const registerOrder = async () => {
    const data = {
      // Pola wymagane do rejestracji transakcji i stworzenia zamówienia
      sessionId: order.sessionId,
      type: order.type,
      galleriesQuantity: order.galleriesQuantity,
      price: order.price,
      amount: order.amount,
      userEmail: order.email,
      description: order.description,
      // Pola wymagane do stworzenia zamówienia
      userName: order.userName,
      userSurname: order.userSurname,
      userEmail: order.userEmail,
      address: order.address,
      city: order.city,
      zipcode: order.zipcode,
      taxNumber: order.taxNumber,
    };

    await axios
      .post(`/orders/register-transaction`, data)
      .then((res) => {
        window.open(`https://secure.przelewy24.pl/trnRequest/${res.data.token}`, "_blank");
        window.location.href = `https://app.maslado.com/dashboard`;
        close();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.topMenu}>
        <div onClick={close} className={styles.returnButton}>
          <ReturnButton title="Cofnij" url="" />
        </div>
      </div>
      <div className={styles.confirmCard}>
        <div className={styles.cardHeader}>
          <h6>Potwierdź szczegóły płatności</h6>
          <p>Po zatwierdzeniu zostaniesz przekierowany na stronę dostawcy płatności.</p>
        </div>
        <ul>
          <li>
            <span>{order.type}</span>
            <p>Liczba dodatkowych galerii: {order.galleriesQuantity}</p>
          </li>
          <li>
            <span>Numer zamówienia:</span>
            <p>{order.sessionId}</p>
          </li>
          <li>
            <span>Nazwa kupującego:</span>
            <p>{`${order.userName} ${order.userSurname}`}</p>
          </li>
          <li>
            <span>Adres email:</span>
            <p>{order.userEmail}</p>
          </li>
          <li>
            <span>Kwota do zapłaty:</span>
            <p>{order.price} PLN</p>
          </li>
        </ul>

        <div className={styles.buttons}>
          <button onClick={registerOrder} className={styles.confirmOrder}>
            Przechodzę do płatności
          </button>
          <button onClick={close} className={styles.declineOrder}>
            Anuluj
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirm;
