import styles from "./settingsPage.module.scss";
import { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../store/AppContextProvider";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ProfileSettings from "./components/profileSettings/ProfileSettings";
import Page from "../../components/layout/page/Page";
import LoadingBar from "../../components/layout/LoadingBar";
import Alert from "../../components/layout/Alert/Alert";

const UserSettingsPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [isProfileTab, setIsProfileTab] = useState(true);
  const [alert, setAlert] = useState({
    message: "",
    type: "",
  });
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    getUser();
  }, []);

  const photosRef = useRef();
  let params = useParams();

  const getUser = async () => {
    setLoading(true);
    await axios
      .get("/users")
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => error);
    setLoading(false);
  };

  const settingsComponent = () => {
    if (isProfileTab) {
      return <ProfileSettings user={user} setAlert={setAlert} />;
    }
  };

  return (
    <Page>
      {loading ? (
        <LoadingBar />
      ) : (
        <div className={styles.container}>
          <h3>Ustawienia</h3>
          <div className={styles.tabs}>
            <div
              className={styles.tab}
              style={isProfileTab ? { fontWeight: "500", borderBottom: "2px solid #0099F0" } : {}}
            >
              Profil
            </div>
          </div>
          {settingsComponent()}
        </div>
      )}
      {alert.message ? (
        <Alert
          alert={alert}
          close={() =>
            setAlert({
              message: "",
              type: "",
            })
          }
        />
      ) : (
        ""
      )}
    </Page>
  );
};

export default UserSettingsPage;
