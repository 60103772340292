function MenuHamburger() {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 31.875H37.5M7.5 22.5H37.5M7.5 13.125H37.5"
        stroke="#191919"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MenuHamburger;
