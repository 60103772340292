function Logout({ color }) {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6667 3.65875L14.5833 3.65875C17.1314 3.65875 17.0833 6.43653 17.0833 9.21431C17.0833 11.9921 17.1314 14.7699 14.5833 14.7699H11.6667M2.5 9.21431L12.5 9.21431M2.5 9.21431L5.83333 6.43653M2.5 9.21431L5.83333 11.9921"
        stroke="#6D6F71"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Logout;
