import styles from "./customerPage.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../../static/svg/Logo";
import PrimaryButton from "../../layout/primaryButton/PrimaryButton";

const CustomerPage = (props) => {
  let navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("organization");
    navigate(`/customer-login`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <NavLink to="/customer-panel">
          <Logo />
        </NavLink>
        <PrimaryButton title="Wyloguj się" backgroundColor="#FFF" borderColor="#000" color="#000" onClick={logout} />
      </div>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

export default CustomerPage;
