import styles from "./gallery.module.scss";
import { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../../../store/AppContextProvider";
import { IoTrashBin } from "react-icons/io5";
import Alert from "../../../../../components/layout/Alert/Alert";
import ConfirmationBox from "../../../../../components/layout/confirmationBox/ConfirmationBox";
import Photos from "../photos/Photos";
import GalleryFilter from "../../../../../components/galleries/galleryFilter/GalleryFilter";
import GallerySorter from "../../../../../components/galleries/gallerySorter/GallerySorter";

const Gallery = ({
  getGallery,
  galleryStatus,
  uneditedPhotos,
  photos,
  editedPhotos,
  setFilter,
  setSorter,
  addPhotos,
  showFilteredPhotos,
  showSortedPhotos,
  loadingPhotos,
  showGallery,
  isEdited,
}) => {
  const [loading, setLoading] = useState(false);
  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const [message, setMessage] = useState("");
  const [needConfirmation, setNeedConfirmation] = useState(false);
  const [question, setQuestion] = useState("");
  const [action, setAction] = useState();

  const { user, setUser } = useContext(UserContext);

  const photosRef = useRef();
  const params = useParams();

  useEffect(() => {
    // Po zmianie typu wyświetlanych zdjęć usuwam tablicę ze zdjęciami do usunięcia
    setDeletedPhotos([]);
  }, [showFilteredPhotos]);

  const handleDeletedPhotos = async (photo, event) => {
    setLoading(true);

    let photosToDelete = [...deletedPhotos];
    if (event.target.checked) {
      photosToDelete.push(photo.id);
      setDeletedPhotos(photosToDelete);
    } else {
      photosToDelete = photosToDelete.filter((value) => {
        return value != photo.id;
      });
      setDeletedPhotos(photosToDelete);
    }

    setLoading(false);
  };

  const deleteMultiplePhotos = async (photo, index) => {
    setLoading(true);
    console.log("Asd");
    const data = {
      deletedPhotos: deletedPhotos,
      galleryStatus: galleryStatus,
      path: user.organization_name,
    };

    await axios
      .post(`/upload/delete`, data)
      .then((res) => {
        let updatedPhotos = photos.filter((photo) => {
          return !res.data.deletedPhotos.find((deletedPhoto) => deletedPhoto === photo.id);
        });

        const data = {
          photos: updatedPhotos,
        };

        axios
          .put(`/galleries/${params.id}`, data)
          .then((res) => {
            setMessage("Poprawnie usunięto zdjęcia");
            getGallery();
          })
          .catch((error) => {
            setMessage(error.message);
          });
      })
      .catch((error) => {
        setMessage(error.message);
      });

    setLoading(false);
  };

  const filteredEditedPhotos = () => {
    return (
      <Photos
        photos={editedPhotos}
        galleryStatus={galleryStatus}
        getGallery={getGallery}
        handleDeletedPhotos={handleDeletedPhotos}
      />
    );
  };

  const filterValues = [
    { value: "marked", valueName: "Zaznaczone" },
    { value: "selected", valueName: "Wybrane" },
  ];

  const changeFilter = (value) => {
    setFilter(value);
  };

  const changeSorter = (value) => {
    setSorter(value);
  };

  const filteredPhotos = () => {
    let filterPhotos = [];
    let editedPhotos = photos.filter((photo) => photo.edited === true);
    let markedPhotos = photos.filter((photo) => photo.marked === true);
    let selectedPhotos = photos.filter((photo) => photo.selected === true);

    if (showFilteredPhotos === "all") {
      filterPhotos = uneditedPhotos;
    } else if (showFilteredPhotos === "marked") {
      filterPhotos = markedPhotos;
    } else if (showFilteredPhotos === "selected") {
      filterPhotos = selectedPhotos;
    } else if (showFilteredPhotos === "edited") {
      filterPhotos = editedPhotos;
    }

    const alphabetical = (arr, getter, order = "ascending") =>
      arr.sort(
        order === "descending"
          ? (a, b) => getter(b).localeCompare(getter(a))
          : (a, b) => getter(a).localeCompare(getter(b))
      );

    if (showSortedPhotos === "ascending") {
      alphabetical(filterPhotos, (g) => g.title);
    } else if (showSortedPhotos === "descending") {
      alphabetical(filterPhotos, (g) => g.title, "descending");
    }

    return (
      <>
        <div className={styles.controls}>
          <div className={styles.filtersAndSorters}>
            <GalleryFilter
              title="Wszystkie"
              firstValue="all"
              label="Filtruj"
              values={filterValues}
              onChange={(e) => changeFilter(e.target.value)}
            />
            <div className={styles.sorter}>
              <GallerySorter changeSorter={changeSorter} />
            </div>
          </div>
          <div className={styles.photoCounter}>
            <p>Liczba zdjęć: {photos.length}</p>
          </div>
        </div>
        <Photos
          photos={filterPhotos}
          galleryStatus={galleryStatus}
          getGallery={getGallery}
          handleDeletedPhotos={handleDeletedPhotos}
        />
      </>
    );
  };

  const changeGallery = (data) => {
    showGallery(data);
  };

  const confirmAction = (question, action) => {
    setQuestion(question);
    setNeedConfirmation(true);
    setAction(() => action);
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div
          onClick={() => changeGallery(false)}
          className={styles.tab}
          style={isEdited ? {} : { fontWeight: "500", borderBottom: "2px solid #0099F0" }}
        >
          Zdjęcia do wyboru
        </div>
        <div
          onClick={() => changeGallery(true)}
          className={styles.tab}
          style={isEdited ? { fontWeight: "500", borderBottom: "2px solid #0099F0" } : {}}
        >
          Zdjęcia gotowe
        </div>
      </div>
      {deletedPhotos && deletedPhotos.length > 0 ? (
        <div className={styles.actionMenu}>
          <div
            className={styles.deleteButton}
            onClick={() => {
              confirmAction("Czy na pewno chcesz usunąć zaznaczone zdjęcia?", deleteMultiplePhotos);
            }}
          >
            <p>Usuń wybrane</p>
            <IoTrashBin size="20" color="red" />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={styles.gallery}>{isEdited ? filteredEditedPhotos() : filteredPhotos()}</div>

      {needConfirmation && (
        <ConfirmationBox
          positiveAction={() => action()}
          negativeAction={() => {
            setNeedConfirmation(false);
          }}
          question={question}
        />
      )}
    </div>
  );
};

export default Gallery;
