import styles from "./customersList.module.scss";
import { useState } from "react";
import CustomerItem from "../customer-item/CustomerItem";

const CustomersList = ({ customers }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const customersPerPage = 5;
  const pagesVisited = pageNumber * customersPerPage;
  const pagesQuantity = Math.ceil(customers.length / customersPerPage);
  const pagesNumbers = [...Array(pagesQuantity + 1).keys()].slice(1);

  const previousPage = () => {
    if (pageNumber !== 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPage = () => {
    if (pageNumber != pagesQuantity - 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  const changePage = (number) => {
    setPageNumber(number - 1);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.galleriesList}>
          {customers.slice(pagesVisited, pagesVisited + customersPerPage).map((customer) => {
            return <CustomerItem key={customer._id} customer={customer} />;
          })}
        </div>
        {customers.length > customersPerPage && (
          <div className={styles.pagination}>
            <span onClick={previousPage}>Poprzednia strona</span>
            <div className={styles.numbersContainer}>
              {pagesNumbers.map((number, index) => (
                <span
                  onClick={() => changePage(number)}
                  key={index}
                  className={pageNumber + 1 === number ? styles.active : ""}
                >
                  {number}
                </span>
              ))}
            </div>
            <span onClick={nextPage}>Następna strona</span>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomersList;
