function CalendarIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0013 2.08301C13.0013 1.80687 12.7774 1.58301 12.5013 1.58301C12.2252 1.58301 12.0013 1.80687 12.0013 2.08301H13.0013ZM12.0013 5.41634C12.0013 5.69248 12.2252 5.91634 12.5013 5.91634C12.7774 5.91634 13.0013 5.69248 13.0013 5.41634H12.0013ZM8.0013 2.08301C8.0013 1.80687 7.77744 1.58301 7.5013 1.58301C7.22516 1.58301 7.0013 1.80687 7.0013 2.08301H8.0013ZM7.0013 5.41634C7.0013 5.69248 7.22516 5.91634 7.5013 5.91634C7.77744 5.91634 8.0013 5.69248 8.0013 5.41634H7.0013ZM17.0701 9.58301L17.5698 9.56478L17.5698 9.56477L17.0701 9.58301ZM12.0013 2.08301V5.41634H13.0013V2.08301H12.0013ZM7.0013 2.08301V5.41634H8.0013V2.08301H7.0013ZM17.0701 9.08301H2.93241V10.083H17.0701V9.08301ZM10.0013 16.9997C7.31159 16.9997 5.72409 16.5779 4.78194 15.6357C3.83979 14.6936 3.41797 13.1061 3.41797 10.4163H2.41797C2.41797 13.1433 2.82948 15.0975 4.07483 16.3428C5.32018 17.5882 7.27435 17.9997 10.0013 17.9997V16.9997ZM16.5846 10.4163C16.5846 13.1061 16.1628 14.6936 15.2207 15.6357C14.2785 16.5779 12.691 16.9997 10.0013 16.9997V17.9997C12.7283 17.9997 14.6824 17.5882 15.9278 16.3428C17.1731 15.0975 17.5846 13.1433 17.5846 10.4163H16.5846ZM3.41797 10.4163C3.41797 10.1334 3.42257 9.86187 3.43208 9.60124L2.43275 9.56477C2.42274 9.83887 2.41797 10.1227 2.41797 10.4163H3.41797ZM3.43208 9.60124C3.5175 7.26054 3.99425 5.87796 4.93836 5.05052C5.89657 4.21072 7.45636 3.83301 10.0013 3.83301V2.83301C7.41788 2.83301 5.53 3.20229 4.27925 4.29848C3.01439 5.40703 2.52089 7.14945 2.43275 9.56477L3.43208 9.60124ZM10.0013 3.83301C12.5462 3.83301 14.106 4.21072 15.0642 5.05052C16.0083 5.87796 16.485 7.26054 16.5705 9.60124L17.5698 9.56477C17.4817 7.14945 16.9882 5.40704 15.7233 4.29848C14.4726 3.20228 12.5847 2.83301 10.0013 2.83301V3.83301ZM16.5705 9.60124C16.58 9.86195 16.5846 10.1335 16.5846 10.4163H17.5846C17.5846 10.1226 17.5798 9.8388 17.5698 9.56478L16.5705 9.60124Z"
        fill="#191919"
        fillOpacity="0.6"
      />
    </svg>
  );
}

export default CalendarIcon;
