import { useEffect } from "react";
import styled from "styled-components";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 98vw;
  height: 98vh;
  z-index: 99;
  background: transparent;
`;

const Message = styled.div`
  position: absolute;
  left: 20px;
  bottom: 40px;
  padding: 0 20px;
  min-width: 200px;
  height: 36px;
  background: #151414;
  border-radius: 3px;
  color: white;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  p {
    color: white;
  }
`;

const Modal = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.closeModal();
    }, 3000);
  }, []);

  return (
    <>
      <ModalWrapper onClick={props.closeModal}>
        <Message>
          <p>{props.message}</p>
        </Message>
      </ModalWrapper>
    </>
  );
};

export default Modal;
