function UserIcon({ color }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.41536 18.0001H14.582V17.0001H5.41536V18.0001ZM9.9987 11.5834C9.17885 11.5834 7.44155 11.5634 5.90557 11.9934C5.13498 12.2091 4.37114 12.5492 3.79424 13.0995C3.20246 13.6639 2.83203 14.4272 2.83203 15.4168H3.83203C3.83203 14.706 4.0866 14.2025 4.48441 13.8231C4.89709 13.4295 5.48742 13.1489 6.17516 12.9563C7.55585 12.5698 9.15188 12.5834 9.9987 12.5834V11.5834ZM9.9987 12.5834C10.8455 12.5834 12.4415 12.5698 13.8222 12.9563C14.51 13.1489 15.1003 13.4295 15.513 13.8231C15.9108 14.2025 16.1654 14.706 16.1654 15.4168H17.1654C17.1654 14.4272 16.7949 13.6639 16.2032 13.0995C15.6263 12.5492 14.8624 12.2091 14.0918 11.9934C12.5558 11.5634 10.8185 11.5834 9.9987 11.5834V12.5834ZM2.83203 15.4168C2.83203 16.8435 3.98863 18.0001 5.41536 18.0001V17.0001C4.54091 17.0001 3.83203 16.2912 3.83203 15.4168H2.83203ZM16.1654 15.4168C16.1654 16.2912 15.4565 17.0001 14.582 17.0001V18.0001C16.0088 18.0001 17.1654 16.8435 17.1654 15.4168H16.1654ZM12.832 5.83333C12.832 7.39814 11.5635 8.66667 9.9987 8.66667V9.66667C12.1158 9.66667 13.832 7.95042 13.832 5.83333H12.832ZM9.9987 8.66667C8.43389 8.66667 7.16536 7.39814 7.16536 5.83333H6.16536C6.16536 7.95042 7.88161 9.66667 9.9987 9.66667V8.66667ZM7.16536 5.83333C7.16536 4.26853 8.43389 3 9.9987 3V2C7.88161 2 6.16536 3.71624 6.16536 5.83333H7.16536ZM9.9987 3C11.5635 3 12.832 4.26853 12.832 5.83333H13.832C13.832 3.71624 12.1158 2 9.9987 2V3Z"
        fill={color}
        fillOpacity="1"
      />
    </svg>
  );
}

export default UserIcon;
