import React, { useEffect, useState } from "react";
import Page from "../../components/layout/page/Page";
import styles from "./accountPage.module.scss";
import LoadingBar from "../../components/layout/LoadingBar";
import axios from "axios";

const AccountPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  // Funkcja do pobierania zamówień
  const getOrders = async () => {
    setLoading(true);
    try {
      await axios.get("/orders").then((res) => {
        setOrders(res.data);
        console.log(res.data);
      });
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getOrders();
  }, []);

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    return date.toLocaleString("pl-PL", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.headerTitle}>
          <h3>Historia zamówień</h3>
        </div>
        {loading ? (
          <LoadingBar />
        ) : (
          <div className={styles.orders}>
            <table>
              <thead>
                <tr>
                  <th>Data i godzina</th>
                  <th>Status</th>
                  <th>Rodzaj pakietu</th>
                  <th>Ilość galerii</th>
                  <th>Cena</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order._id}>
                    <td>{formatDate(order.createdAt)}</td>
                    <td>{order.isVerified ? "Yes" : "No"}</td>
                    <td>{order.type}</td>
                    <td>{order.galleriesQuantity}</td>
                    <td>
                      {order.price},- {order.currency}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Page>
  );
};

export default AccountPage;
