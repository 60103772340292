import styles from "./galleryItem.module.scss";
import { useNavigate } from "react-router-dom";
import StatusPill from "../statusPill/StatusPill";
import CalendarIcon from "../../../static/svg/CalendarIcon";
import UserIcon from "../../../static/svg/UserIcon";

const GalleryItem = ({ gallery }) => {
  let navigate = useNavigate();

  const openGallery = () => {
    navigate(`/galleries/${gallery._id}`);
  };

  return (
    <div className={styles.container} onClick={openGallery}>
      <div className={styles.primaryInfos}>
        <div className={styles.cover}>
          {gallery.photos && gallery.photos.length ? <img src={gallery.photos[0].url} alt="cover" /> : ""}
        </div>
        <div className={styles.primaryInfo}>
          <h2 className={styles.title}>{gallery.title}</h2>
          <div className={styles.secondaryInfos}>
            <div className={styles.secondaryInfo}>
              <CalendarIcon />
              <p> {gallery.date}</p>
            </div>
            <div className={styles.secondaryInfo}>
              <UserIcon />
              <p>{gallery.customerName}</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.minorInfos}>
        <div className={styles.minorInfo}>
          <p>
            Rodzaj sesji <span>{gallery.type}</span>
          </p>
        </div>
        <div className={styles.minorInfo}>
          <p>
            Wyświetlono <span>{gallery.viewDate ? gallery.viewDate : "nie"}</span>
          </p>
        </div>
        <div className={styles.sessionStatus}>
          <StatusPill status={gallery.status} />
        </div>
      </div>
    </div>
  );
};

export default GalleryItem;
