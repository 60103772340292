import { useState } from "react";
import styles from "./gallerySorter.module.scss";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";

const GallerySorter = ({ changeSorter }) => {
  const [sortbyName, setSortByName] = useState("");

  const handleSortByName = () => {
    switch (sortbyName) {
      case "":
        setSortByName("ascending");
        changeSorter("ascending");
        break;
      case "ascending":
        setSortByName("descending");
        changeSorter("descending");
        break;
      case "descending":
        setSortByName("ascending");
        changeSorter("ascending");
        break;
    }
  };

  return (
    <div className={styles.container} onClick={handleSortByName}>
      <div className={styles.title}>
        <p>Sortuj</p>
      </div>
      <div className={styles.sortingDirection}>
        <p>A</p>
        <p>Z</p>
      </div>
      <div className={styles.sortingArrow}>
        {sortbyName === "" && ""}
        {sortbyName === "ascending" && <FaLongArrowAltDown />}
        {sortbyName === "descending" && <FaLongArrowAltUp />}
      </div>
    </div>
  );
};

export default GallerySorter;
