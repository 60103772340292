import { Navigate } from "react-router-dom";

const Protected = ({ children }) => {
  if (!localStorage.getItem("token")) {
    console.log(localStorage.getItem("token"));
    return <Navigate to="/login" replace />;
  }

  if (localStorage.getItem("role") === "customer") {
    return <Navigate to="/customer-panel" replace />;
  }

  return children;
};
export default Protected;
