import { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";

import styles from "./controls.module.scss";
import StatusPill from "../../../../../components/galleries/statusPill/StatusPill";
import CalendarIcon from "../../../../../static/svg/CalendarIcon";
import UserIcon from "../../../../../static/svg/UserIcon";
import PrimaryButton from "../../../../../components/layout/primaryButton/PrimaryButton";

const Controls = ({
  gallery,
  customer,
  photos,
  sendGallery,
  sendEditedGallery,
  handlePhotos,
  handleEditedPhotos,
  confirmAction,
}) => {
  let navigate = useNavigate();
  let params = useParams();
  const photosRef = useRef();

  return (
    <div className={styles.container}>
      <div className={styles.mainControls}>
        <div className={styles.headerTitle}>
          <h3>{gallery.title}</h3>
          <StatusPill status={gallery.status} />
        </div>
        <div className={styles.links}>
          <div className={styles.date}>
            <CalendarIcon />
            <p>{gallery.date}</p>
          </div>
          <div className={styles.customerProfile} onClick={() => navigate(`/customers/${customer._id}`)}>
            <UserIcon color="#0099F0" />
            <p>{customer.name}</p>
          </div>
          <NavLink to={`/galleries/resume/${params.id}`}>Podsumowanie</NavLink>
          <NavLink to={`/galleries/settings/${params.id}`}>Ustawienia</NavLink>
        </div>
        <div className={styles.actionButtons}>
          {gallery.editedPhotos?.length > 0 ? (
            <PrimaryButton
              onClick={() => {
                confirmAction("Czy na pewno chcesz przesłać gotowe zdjęcia swojemu klientowi?", sendEditedGallery);
              }}
              title="Wyślij gotowe zdjęcia"
              backgroundColor="#FFF"
              borderColor="#0099F0"
              color="#0099F0"
            />
          ) : (
            <PrimaryButton
              onClick={() => {
                confirmAction(
                  "Czy na pewno chcesz przesłać galerię do klienta? Po jej wysłaniu nie będzie możliwości edycji.",
                  sendGallery
                );
              }}
              title="Wyślij do klienta"
              backgroundColor="#FFF"
              borderColor="#0099F0"
              color="#0099F0"
            />
          )}

          {gallery.status === "new" ? (
            <>
              <PrimaryButton
                onClick={() => photosRef.current.click()}
                title="Dodaj zdjęcia"
                backgroundColor="#0099F0"
                borderColor="#0099F0"
                color="#FFF"
              />

              <input
                onChange={handlePhotos}
                ref={photosRef}
                name="photos"
                hidden
                multiple
                id="photos"
                type="file"
                accept=".jpg,.png,.jpeg"
              />
            </>
          ) : (
            <>
              <PrimaryButton
                onClick={() => photosRef.current.click()}
                title="Dodaj gotowe zdjęcia"
                backgroundColor="#0099F0"
                borderColor="#0099F0"
                color="#FFF"
              />

              <input
                onChange={handleEditedPhotos}
                ref={photosRef}
                name="photos"
                hidden
                multiple
                id="photos"
                type="file"
                accept=".jpg,.png,.jpeg"
              />
            </>
          )}
        </div>
      </div>

      {/* <div className={styles.mainControls}>
        <button onClick={() => navigate(`/galleries/resume/${params.id}`)}>Podsumowanie</button>
        <button onClick={() => navigate(`/galleries/settings/${params.id}`)}>Ustawienia</button>

        {props.gallery.status != "done" && (
          <button
            onClick={() =>
              props.confirmAction(
                "Czy na pewno chcesz przesłać galerię do klienta? Po jej wysłaniu nie będzie możliwości edycji.",
                props.sendGallery
              )
            }
          >
            {props.gallery.status === "new" ? "Wyślij do klienta" : "Wyślij ponownie"}
          </button>
        )}

        {props.editedPhotos && props.editedPhotos.length > 0 && props.gallery.status != "done" ? (
          <button
            onClick={() =>
              props.confirmAction("Czy na pewno chcesz przesłać klientowi gotowe zdjęcia?", props.sendEditedGallery)
            }
            style={{ width: "150px" }}
          >
            Wyślij gotowe zdjęcia
          </button>
        ) : (
          ""
        )}
      </div>

      <div className={styles.secondaryControls}>
        <div className={styles.filtersAndSorters}>
          <GalleryFilter
            title="Wszystkie"
            firstValue="all"
            values={filterValues}
            onChange={(e) => changeFilter(e.target.value)}
          />
          <GallerySorter changeSorter={changeSorter} />
        </div>

        <div className={styles.photosCount}>
          <p>Liczba zdjęć: {photosCounter()} z 500</p>
        </div>
      </div> */}
    </div>
  );
};

export default Controls;
