import styles from "./modalDownloadPhotos.module.scss";
import CloseButton from "../../../../../static/svg/CloseButton";

const ModalDownloadPhotos = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.controls}>
          <div className={styles.zmienBoNieWiem}></div>
          <div className={styles.closeButton} onClick={props.closeModal}>
            <CloseButton color="#151414" width={20} height={20} />
          </div>
        </div>
        <div className={styles.main}>
          <p>Pobierz wszystkie gotowe zdjęcia </p>
          <a href={props.downloadURL} onClick={props.closeModal} className={styles.downloadButton}>
            Pobierz
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalDownloadPhotos;
