import styles from "./resetPassword.module.scss";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/layout/Navbar";
import FormInput from "../../components/layout/Form/form-input/FormInput";

const ResetPasswordPage = (props) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [validationStep, setValidationStep] = useState("emailValidation");
  const [errors, setErrors] = useState("");

  const navigate = useNavigate();

  const sendValidationCode = async (e) => {
    e.preventDefault();

    const data = {
      email: email,
    };

    await axios
      .post("/users/send-reset-code", data)
      .then((res) => {
        if (res.data.message === "Kod weryfikacyjny został wysłany na podany adres email") {
          setValidationStep("codeValidation");
          setErrors("");
        }
      })
      .catch((error) => setErrors(error.response.data.message));
  };

  const validateCode = async (e) => {
    e.preventDefault();

    const data = {
      email: email,
      code: code,
    };

    await axios
      .post("/users/validate-reset-code", data)
      .then((res) => {
        console.log(res.data);
        if (res.data.success === true) {
          setValidationStep("changePassword");
          setErrors("");
        }
      })
      .catch((error) => setErrors(error.response.data.message));
  };

  const changePassword = async (e) => {
    e.preventDefault();

    const data = {
      email: email,
      password: password,
      passwordConfirm: passwordConfirm,
    };

    await axios
      .put("/users/change-password", data)
      .then((res) => {
        if (res.data.success === true) {
          navigate("/login");
          setErrors("");
        }
      })
      .catch((error) => setErrors(error.response.data.message));
  };

  return (
    <>
      <Navbar />
      <div className={styles.container}>
        {validationStep === "emailValidation" && (
          <form onSubmit={sendValidationCode}>
            <div className={styles.header}>
              <h2>Odzyskiwanie hasła</h2>
            </div>
            <div className={styles.input}>
              <label htmlFor="email">Podaj adres email</label>
              <input onChange={(e) => setEmail(e.target.value)} type="email" name="email" placeholder="Adres email" />
            </div>
            {errors ? <p style={{ color: "red" }}>{errors}</p> : ""}

            <button type="submit">Odzyskaj hasło</button>
          </form>
        )}
        {validationStep === "codeValidation" && (
          <form onSubmit={validateCode}>
            <div className={styles.header}>
              <h2>Kod aktywacyjny</h2>
            </div>
            <div className={styles.input}>
              <label htmlFor="code">Podaj kod otrzymany w wiadomości email</label>
              <input onChange={(e) => setCode(e.target.value)} type="text" name="code" placeholder="Kod aktywacyjny" />
            </div>
            {errors ? <p style={{ color: "red" }}>{errors}</p> : ""}
            <button type="submit">Potwiedź</button>
          </form>
        )}
        {validationStep === "changePassword" && (
          <form onSubmit={changePassword}>
            <div className={styles.header}>
              <h2>Nowe hasło</h2>
            </div>
            <div className={styles.input}>
              <FormInput
                onChange={(e) => setPassword(e.target.value)}
                type="text"
                name="password"
                label="Podaj nowe hasło"
                placeholder="Nowe hasło"
                errorMessage="Hasło musi składać się z 8 - 20 znaków (bez polskich liter) oraz zawierać przynajmniej jedną cyfrę, jedną wielką literę i jeden znak specjalny."
                pattern="^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$"
                required
              />
              <div className={styles.input}>
                <FormInput
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  type="text"
                  name="passwordConfirm"
                  label="Potwierdź nowe hasło"
                  placeholder="Nowe hasło"
                  errorMessage="Hasła muszą być takie same."
                  pattern={password}
                  required
                />
              </div>
            </div>
            {errors ? <p style={{ color: "red" }}>{errors}</p> : ""}
            <button type="submit">Potwiedź</button>
          </form>
        )}
      </div>
    </>
  );
};

export default ResetPasswordPage;
