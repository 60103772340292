import { createContext, useEffect, useState } from "react";
import axios from "axios";

export const UserContext = createContext();

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [uploadedPhotoCount, setUploadedPhotoCount] = useState(1);

  let values = {
    user,
    setUser,
    uploadedPhotoCount,
    setUploadedPhotoCount,
  };

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export default AppContextProvider;
