import { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "../../../store/AppContextProvider";
import FileDownload from "js-file-download";
import styles from "./galleryResume.module.scss";
import axios from "axios";
import useCloseOutside from "../../../helpers/closeOutside";
import ArrowBack from "../../../static/svg/ArrowBack";
import Page from "../../../components/layout/page/Page";
import SettingsIcon from "../../../static/svg/SettingsIcon";
import ResumeSettings from "./components/resumeSettings/ResumeSettings";
import ConfirmationBox from "../../../components/layout/confirmationBox/ConfirmationBox";
import Alert from "../../../components/layout/Alert/Alert";
import ReturnButton from "../../../components/layout/returnButton/ReturnButton";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import ResumeInfoTable from "./components/resumeInfoTable/ResumeInfoTable";
import LoadingBar from "../../../components/layout/LoadingBar";
import Selections from "./components/selections/Selections";

const GalleryResume = (props) => {
  const [gallery, setGallery] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [customer, setCustomer] = useState({});
  const [customerID, setCustomerID] = useState("");
  const [shotsPrice, setShotsPrice] = useState(0);
  const [printingsPrice, setPrintingsPrice] = useState();
  const [remain, setRemain] = useState();
  const [selectedPhotos, setSelectedPhotos] = useState(false);
  const [selections, setSelections] = useState([]);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [needConfirmation, setNeedConfirmation] = useState(false);
  const [question, setQuestion] = useState("");
  const [action, setAction] = useState();
  const [resumeSettingsTrigger, setResumeSettingsTrigger] = useState(false);

  const { user, setUser } = useContext(UserContext);

  let navigate = useNavigate();
  let params = useParams();
  const popup = useRef();

  useEffect(() => {
    getGallery();
    getCustomer();
    if (gallery) {
      if (gallery.selectedShotsQt > gallery.shotsQt) {
        setShotsPrice((gallery.selectedShotsQt - gallery.shotsQt) * gallery.additionalShotPrice);
      }
      let printingsValue = 0;
      gallery.photos.forEach((photo) => {
        printingsValue = printingsValue + photo.printingsPrice;
      });
      setPrintingsPrice(printingsValue);
    }
  }, [customerID]);

  const getGallery = async () => {
    setLoading(true);
    await axios.get(`/galleries/${params.id}`).then((res) => {
      setGallery(res.data);
      setPhotos(res.data.photos);
      setCustomerID(res.data.customer);
      let selectedPhotos = res.data.photos.filter((photo) => photo.selected === true);
      setSelectedPhotos(selectedPhotos);
      axios.get(`/selections/${res.data._id}`).then((res) => {
        setSelections(res.data);
      });
    });
    setLoading(false);
  };

  const getCustomer = async () => {
    if (customerID) {
      await axios.get(`/customers/${customerID}`).then((res) => {
        setCustomer(res.data);
      });
    }
  };

  const downloadResume = async () => {
    const data = {
      title: gallery.title,
      customer: customer.name,
      resumeID: gallery._id,
      selections: selections,
    };

    await axios({
      method: "POST",
      url: `/galleries/download-resume/${params.id}`,
      data: data,
    })
      .then((res) => {})
      .catch((error) => console.log(error));

    await axios({
      method: "GET",
      url: `/galleries/download-resume/${params.id}`,
      responseType: "blob",
    })
      .then((res) => {
        FileDownload(res.data, `${gallery.title}.txt`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const confirmAction = (question, action) => {
    setQuestion(question);
    setNeedConfirmation(true);
    setAction(() => action);
  };

  useCloseOutside(popup, () => setResumeSettingsTrigger(false));

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.returnButton}>
          <ReturnButton title="Powrót do galerii" url={`/galleries/${params.id}`} />
        </div>
        {loading ? (
          <LoadingBar />
        ) : (
          <>
            <div className={styles.headerTitle}>
              <h3>Podsumowanie</h3>
              <div className={styles.resumeSettings}>
                <div
                  onClick={() => setResumeSettingsTrigger(!resumeSettingsTrigger)}
                  className={styles.resumeSettingsIcon}
                >
                  <BsThreeDotsVertical size={25} color="#191919" />
                </div>
                {resumeSettingsTrigger && (
                  <ResumeSettings
                    confirmAction={confirmAction}
                    close={() => setResumeSettingsTrigger(false)}
                    gallery={gallery}
                    getGallery={getGallery}
                    photos={photos}
                  />
                )}
              </div>
            </div>
            <ResumeInfoTable gallery={gallery} shotsPrice={shotsPrice} printingsPrice={printingsPrice} />
            <Selections selections={selections} title={gallery.title} />
          </>
        )}
      </div>

      {needConfirmation && (
        <ConfirmationBox
          positiveAction={() => action()}
          negativeAction={() => {
            setNeedConfirmation(false);
          }}
          question={question}
        />
      )}

      {message ? <Alert message={message} close={() => setMessage("")} /> : ""}
    </Page>
  );
};

export default GalleryResume;
