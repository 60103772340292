import { useState } from "react";
import styles from "./resumeInfoTable.module.scss";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const ResumeInfoTable = ({ gallery, shotsPrice, printingsPrice }) => {
  const [totalPriceDetails, setTotalPriceDetails] = useState(false);

  const totalPrice = gallery.price + shotsPrice + printingsPrice;
  const remainingPrice = gallery.price + shotsPrice + printingsPrice - gallery.paid;

  const printingsCount = (printing) => {
    let quantity = 0;
    if (printing.size === "10 x 15") {
      gallery.photos.forEach((photo) => {
        return (quantity = quantity + photo.printings[0].quantity);
      });
    } else if (printing.size === "15 x 21") {
      gallery.photos.forEach((photo) => {
        return (quantity = quantity + photo.printings[1].quantity);
      });
    } else if (printing.size === "20 x 30") {
      gallery.photos.forEach((photo) => {
        return (quantity = quantity + photo.printings[2].quantity);
      });
    } else if (printing.size === "30 x 40") {
      gallery.photos.forEach((photo) => {
        return (quantity = quantity + photo.printings[3].quantity);
      });
    }
    return quantity;
  };

  return (
    <div className={styles.container}>
      <ul className={styles.resumeTable}>
        <li className={`${styles.totalPriceItem} ${styles.item}`}>
          {totalPriceDetails ? (
            <div style={{ flexDirection: "column", display: "flex", gap: "5px" }}>
              <p>
                Podstawowa cena sesji: <span> {gallery.price} zł</span>
              </p>
              <p>
                Dodatkowe ujęcia: <span>{shotsPrice} zł</span>
              </p>
              <p>
                Wydruki: <span>{printingsPrice} zł</span>
              </p>
            </div>
          ) : (
            <p>
              Całkowita cena sesji (podstawowa cena sesji + dodatkowe ujęcia + wydruki): <span>{totalPrice} zł</span>
            </p>
          )}
          <div onClick={() => setTotalPriceDetails(!totalPriceDetails)} className={styles.questionMarkIcon}>
            <AiOutlineQuestionCircle size={24} color="#0099F0" />
          </div>
        </li>
        <li className={styles.item}>
          <p>
            Zapłacono: <span>{gallery.paid} zł</span>
          </p>
        </li>
        <li className={styles.item}>
          <p>
            Pozostała kwota do zapłaty: <span>{remainingPrice} zł</span>
          </p>
        </li>
        <li className={styles.item}>
          <p>
            Liczba wybranych ujęć: <span>{gallery.selectedShotsQt} szt.</span>
          </p>
        </li>
        <li className={`${styles.item} ${styles.printingsItem}`}>
          {printingsPrice ? (
            <ul className={styles.printingsTable}>
              <p>Wybrane wydruki:</p>
              {gallery.additionalPrintings.map((printing) => (
                <div key={printing._id}>
                  {printingsCount(printing) ? (
                    <li className={styles.printItem}>
                      <p>{printing.size} cm</p>
                      <span style={{ fontSize: "14px" }}>{printingsCount(printing)} szt</span>
                    </li>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </ul>
          ) : (
            <p>
              Wybrane wydruki: <span>brak</span>
            </p>
          )}
        </li>
      </ul>
    </div>
  );
};

export default ResumeInfoTable;
