function MenuContactIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7055 6.29452C18.2618 4.99044 15.8298 4.5 12 4.5C8.1702 4.5 5.73816 4.99044 4.29452 6.29452M19.7055 6.29452C21.0003 7.46413 21.5 9.28823 21.5 12C21.5 17.7353 19.2647 19.5 12 19.5C4.73529 19.5 2.5 17.7353 2.5 12C2.5 9.28823 2.99972 7.46413 4.29452 6.29452M19.7055 6.29452L13.4142 12.5858C12.6331 13.3668 11.3668 13.3668 10.5858 12.5858L4.29452 6.29452"
        stroke="#191919"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MenuContactIcon;
