import styled from "styled-components";
import CustomerGalleryItem from "../customer-gallery-item/CustomerGalleryItem";

const GalleryList = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 3vh;
`;

const CustomerGalleryList = (props) => {
  return (
    <>
      <GalleryList>
        {props.galleries.map((gallery) => {
          return <CustomerGalleryItem key={gallery._id} gallery={gallery} />;
        })}
      </GalleryList>
    </>
  );
};

export default CustomerGalleryList;
