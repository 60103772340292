function ArrowRight({ color, width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.8434 31.3788L9.8419 1.37752C8.61803 0.365166 7.04286 -0.120944 5.46134 0.0256381C3.87981 0.17222 2.42082 0.939549 1.40386 2.15959C0.386897 3.37963 -0.105151 4.95295 0.0354653 6.53502C0.176081 8.11709 0.9379 9.57896 2.1541 10.6005L32.6241 35.9905L2.1541 61.3805C0.933213 62.4006 0.167184 63.8637 0.0242825 65.4482C-0.118619 67.0328 0.373295 68.6093 1.39196 69.8314C2.41063 71.0535 3.87275 71.8213 5.45713 71.966C7.04151 72.1108 8.61858 71.6208 9.8419 70.6036L45.8434 40.6018C46.5184 40.0378 47.0615 39.3325 47.4341 38.5357C47.8067 37.7389 47.9998 36.87 47.9998 35.9903C47.9998 35.1107 47.8067 34.2418 47.4341 33.4449C47.0615 32.6481 46.5184 31.9428 45.8434 31.3788Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowRight;
