import { NavLink } from "react-router-dom";
import styles from "./returnButton.module.scss";
import ReturnArrow from "../../../static/svg/ReturnArrow";

const ReturnButton = ({ title, url }) => {
  return (
    <NavLink to={url} className={styles.returnButton}>
      <ReturnArrow />
      <p>{title}</p>
    </NavLink>
  );
};

export default ReturnButton;
