import { useState } from "react";
import styled from "styled-components";
import CloseButton from "../../../static/svg/CloseButton";

const PrintingsChartWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  padding: 40px 25px;
  background: #e8e8e8;
  width: 100%;
  max-width: 400px;
  height: 400px;
  z-index: 99;
  .printing-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3px;
  }
  .printing-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    button {
      margin-left: 10px;
      padding: 0;
      height: 40px;
      background: white;
      box-shadow: 6px 7px 9px -1px rgb(0 0 0 / 3%);
      border-radius: 2px;
    }
  }

  .printing.disabled {
    display: none;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    cursor: pointer;
  }
`;

const PrintingsChartCarousel = (props) => {
  const [additionalPrintings0, setAdditionalPrintings0] = useState(0);
  const [additionalPrintings1, setAdditionalPrintings1] = useState(0);
  const [additionalPrintings2, setAdditionalPrintings2] = useState(0);
  const [additionalPrintings3, setAdditionalPrintings3] = useState(0);

  const passUpdatePrintings = (printing, index) => {
    let updatedPhoto = props.photo;
    updatedPhoto.printings[0].quantity = additionalPrintings0;
    updatedPhoto.printings[1].quantity = additionalPrintings1;
    updatedPhoto.printings[2].quantity = additionalPrintings2;
    updatedPhoto.printings[3].quantity = additionalPrintings3;

    updatedPhoto.printingsPrice =
      updatedPhoto.printings[0].quantity * updatedPhoto.printings[0].price +
      updatedPhoto.printings[1].quantity * updatedPhoto.printings[1].price +
      updatedPhoto.printings[2].quantity * updatedPhoto.printings[2].price +
      updatedPhoto.printings[3].quantity * updatedPhoto.printings[3].price;

    props.func(updatedPhoto);
  };

  const choosePrintings = (event, index) => {
    if (index === 0) {
      setAdditionalPrintings0(parseInt(event.target.value));
    } else if (index === 1) {
      setAdditionalPrintings1(parseInt(event.target.value));
    } else if (index === 2) {
      setAdditionalPrintings2(parseInt(event.target.value));
    } else if (index === 3) {
      setAdditionalPrintings3(parseInt(event.target.value));
    }
  };

  return (
    <PrintingsChartWrapper>
      <span onClick={props.closeChart} className="close-button">
        <CloseButton color="black" width="18" height="18" />
      </span>
      {props.photo.printings.map((printing, index) => (
        <div key={printing._id} className={`printing ${printing.price ? "" : "disabled"}`}>
          <div className="printing-label">
            <p>
              <span>{printing.size}</span> ({printing.price} zł za sztukę)
            </p>
            <p>
              Wybrano: <span>{printing.quantity}</span>
            </p>
          </div>
          <div className="printing-controls">
            <input
              type="number"
              min="0"
              placeholder="Podaj ilość"
              onChange={(event) => choosePrintings(event, index)}
            />
          </div>
        </div>
      ))}
      <button onClick={() => passUpdatePrintings()}>Zatwierdź</button>
    </PrintingsChartWrapper>
  );
};

export default PrintingsChartCarousel;
