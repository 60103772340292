import styles from "./privacyPolicy.module.scss";
import Navbar from "../../components/layout/Navbar";

const PrivacyPolicyPage = (props) => {
  return (
    <>
      <Navbar />
      <div className={styles.container}>
        <h2>Regulamin korzystania z oprogramowania Maslado</h2>
        <p className={styles.actualizationDate}>Ostatnia aktualizacja: 08.10.2023r.</p>
        <h6>1. Postanowienia wstępne</h6>
        <ol>
          <li>
            <p>
              Niniejszy Regulamin określa szczegółowe zasady świadczenia przez Administratora tj. RoeHillDev usług
              umożliwiających korzystanie z aplikacji zlokalizowanej pod adresem internetowym https://maslado.com/ lub
              jego rozwinięciem. Warunkiem korzystania z Aplikacji jest rejestracja Użytkownika oraz akceptacja
              Regulaminu. Użytkownik akceptując Regulamin oświadcza, że: dane podane w formularzu rejestracji są
              kompletne i zgodne ze stanem faktycznym; wyraża zgodę na przetwarzanie przez Administratora danych
              osobowych dla celów świadczenia usług, tj. udostępnienia Aplikacji; wyraża zgodę na otrzymywanie faktur w
              wersji elektronicznej.
            </p>
          </li>
        </ol>
        <h6>2. Akceptacja warunków</h6>
        <ol>
          <li>
            <p>
              Rejestrując konto w Maslado, przyjmujesz do wiadomości, że przeczytałeś(aś), zrozumiałeś(aś) i akceptujesz
              Regulamin Korzystania oraz naszą Politykę Prywatności.
            </p>
          </li>
        </ol>
        <h6>3. Rejestracja i konto</h6>
        <ol>
          <li>
            <p>
              Aby korzystać z naszych usług, musisz mieć co najmniej 18 lat lub posiadać niezbędną zdolność prawna do
              zawarcia tego porozumienia.
            </p>
          </li>
          <li>
            <p>
              Jesteś odpowiedzialny(a) za dostarczenie dokładnych i aktualnych informacji podczas procesu rejestracji.
              Ponadto, masz obowiązek dbać o bezpieczeństwo danych logowania do swojego konta oraz za wszystkie
              działania, które mają miejsce pod Twoim kontem.
            </p>
          </li>
        </ol>
        <h6>4. Korzystanie z usług</h6>
        <ol>
          <li>
            <p>
              Nasza Aplikacja umożliwia fotografom udostępnianie galerii zdjęć swoim klientom. Zgadzasz się korzystać z
              tych galerii w sposób zgodny z prawem i zgodny z dobrymi obyczajami. <br></br>Wszystkie treści (w tym
              zdjęcia, teksty oraz inne materiały) udostępnione w Aplikacji pozostają własnością odpowiedniego
              właściciela treści (fotografa lub klienta). Nie wolno Ci używać ani dystrybuować żadnej treści z Aplikacji
              bez wyraźnej zgody właściciela treści. <br />
              Zgadzasz się nie angażować w żadne zachowania sprzeczne z prawem, obraźliwe lub szkodliwe podczas
              korzystania z naszej Aplikacji. Obejmuje to, ale nie ogranicza się do, działalności o charakterze
              nękającym, rozsyłania spamu, przesyłania złośliwego oprogramowania lub naruszania praw innych
              użytkowników.
            </p>
          </li>
        </ol>
        <h6>5. Ceny i płatności</h6>
        <ol>
          <li>
            <p>
              Fotografowie mogą oferować swoje usługi za pośrednictwem Aplikacji. Warunki cenowe, terminy płatności i
              opłaty związane z tymi usługami zostaną ustalone przez fotografa i przekazane Ci bezpośrednio. <br />
              Maslado może ułatwiać płatności za usługi fotograficzne, odbitki lub inne produkty. Korzystając z naszych
              usług przetwarzania płatności, zgadzasz się przestrzegać odpowiednich warunków i zasad.
            </p>
          </li>
        </ol>
        <h6>6. Postanowienia końcowe</h6>
        <ol>
          <li>
            <p>
              Zastrzegamy sobie prawo do zakończenia lub zawieszenia Twojego konta według naszego uznania, jeśli
              uważamy, że naruszyłeś(aś) te Warunki Korzystania lub z innych ważnych powodów, bez wcześniejszego
              powiadomienia. <br />
              Możemy aktualizować ten Regulamin Korzystania od czasu do czasu. Otrzymasz powiadomienie o znaczących
              zmianach, jednak Twoim obowiązkiem jest regularne sprawdzanie warunków. Kontynuując korzystanie z
              Aplikacji po wprowadzeniu zmian, oznacza akceptację nowych warunków. <br />
              Korzystając z Maslado, potwierdzasz, że przeczytałeś(aś), zrozumiałeś(aś) i zgadzasz się z Regulaminem
              Korzystania oraz z innymi przypisanymi tu regulacjami.
            </p>
          </li>
        </ol>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
