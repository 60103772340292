import { useState } from "react";
import styles from "./selections.module.scss";
import axios from "axios";
import { AiOutlineDownload } from "react-icons/ai";

const Selections = ({ selections, title }) => {
  const [selectionNumber, setSelectionNumber] = useState(0);

  const handleDownloadResumeFile = async () => {
    const filesToCopy = selections[selectionNumber].photos.map((photo) => `${photo.title}.jpg`);

    const response = await axios.post(
      `/resumes/download-batch`,
      {
        filesToCopy,
      },
      { responseType: "blob" }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `wybrane - ${title} - wybór ${selectionNumber + 1}.bat`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {selections.map((selection, index) => (
          <div
            key={index}
            onClick={() => setSelectionNumber(index)}
            className={styles.tab}
            style={index == selectionNumber ? { fontWeight: "500", borderBottom: "2px solid #0099F0" } : {}}
          >
            {`Wybór ${index + 1}`}
          </div>
        ))}
      </div>

      <div className={styles.selection}>
        <div className={styles.selectionControls}>
          <p>Data dokonania wyboru: {selections[selectionNumber]?.createdAt.slice(0, 10)}</p>
          <div className={styles.downloadResumeFileButton} onClick={handleDownloadResumeFile}>
            <p>Pobierz plik z wyborem</p>
            <AiOutlineDownload size="20" color="#0099F0" />
          </div>
        </div>
        <div className={styles.selectedPhotos}>
          {selections[selectionNumber] &&
            selections[selectionNumber].photos.map((photo) => (
              <div key={photo._id} className={styles.selectedPhoto}>
                <div className={styles.photoPreview}>
                  <div className={styles.cover}>
                    <a href={photo.url} target="blank">
                      <img src={photo.url || photo} alt="" />
                    </a>
                  </div>
                  <div className={styles.title}>
                    {photo.title.length > 22 ? (
                      <p className={styles.title}>
                        {photo.title.substring(0, 10)}...
                        {photo.title.substr(photo.title.length - 10)}
                      </p>
                    ) : (
                      <p className={styles.title}>{photo.title}</p>
                    )}
                  </div>
                </div>
                <div className={styles.selectedPrintings}>
                  {photo.printingsPrice ? (
                    <>
                      {photo.printings.map((printing) => (
                        <div key={printing._id}>
                          {printing.quantity ? (
                            <div className={styles.printing}>
                              <p className={styles.size}>{printing.size}</p>
                              <span>{printing.quantity} szt</span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    "Nie wybrano wydruków"
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Selections;

{
  /* <div className={styles.selection}>
  {selections[selectionNumber] &&
    selections[selectionNumber].photos.map((photo) => (
      <div key={photo._id} className={styles.selectedPhoto}>
        <div className={styles.cover}>
          <a href={photo.url} target="blank">
            <img src={photo.url || photo} alt="" />
          </a>
          {photo.title.length > 22 ? (
            <p className={styles.title}>
              {photo.title.substring(0, 10)}...
              {photo.title.substr(photo.title.length - 10)}
            </p>
          ) : (
            <p className={styles.title}>{photo.title}</p>
          )}
        </div>

        <div>
          <div className={styles.printingsList}>
            {photo.printingsPrice ? (
              <>
                {photo.printings.map((printing) => (
                  <div key={printing._id} className="ps-4">
                    {printing.quantity ? (
                      <div className={styles.printing}>
                        <p className={styles.size}>{printing.size}</p>
                        <span>{printing.quantity} szt</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </>
            ) : (
              "Nie wybrano wydruków"
            )}
          </div>
        </div>
      </div>
    ))}
</div>; */
}
