function ArrowLeft({ color, width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.15642 40.6122L38.1579 70.6135C39.3818 71.6258 40.957 72.1119 42.5385 71.9653C44.12 71.8188 45.579 71.0514 46.596 69.8314C47.6129 68.6114 48.105 67.038 47.9644 65.456C47.8237 63.8739 47.0619 62.412 45.8457 61.3905L15.3757 36.0005L45.8457 10.6105C47.0666 9.59033 47.8326 8.1273 47.9755 6.54275C48.1184 4.9582 47.6265 3.38172 46.6079 2.15961C45.5892 0.937511 44.1271 0.169731 42.5427 0.0249324C40.9583 -0.119866 39.3812 0.370161 38.1579 1.38737L2.15643 31.3892C1.48137 31.9531 0.938364 32.6584 0.565757 33.4553C0.193151 34.2521 2.99262e-05 35.121 2.98493e-05 36.0007C2.97724e-05 36.8803 0.193151 37.7492 0.565757 38.546C0.938363 39.3429 1.48137 40.0482 2.15642 40.6122V40.6122Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowLeft;
