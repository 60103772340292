import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./customerPage.module.scss";
import Page from "../../../components/layout/page/Page";
import PrimaryButton from "../../../components/layout/primaryButton/PrimaryButton";
import Modal from "../../../components/layout/Modal";
import LoadingBar from "../../../components/layout/LoadingBar";
import ArrowBack from "../../../static/svg/ArrowBack";
import Alert from "../../../components/layout/Alert/Alert";
import FormTextarea from "../../../components/layout/Form/FormTextarea";
import ReturnButton from "../../../components/layout/returnButton/ReturnButton";
import FormInput from "../../../components/layout/Form/form-input/FormInput";

const CustomerPage = (props) => {
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    notes: "",
  });

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let params = useParams();
  let navigate = useNavigate();

  const onChange = (e) => {
    setCustomer({ ...customer, [e.target.name]: e.target.value });
  };

  const getCustomer = async () => {
    setLoading(true);
    await axios.get(`/customers/${params.id}`).then((res) => {
      setCustomer(res.data);
    });
    setLoading(false);
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const editCustomer = async () => {
    setLoading(true);

    let data = {
      name: customer.name,
      phone: customer.phone,
      notes: customer.notes,
    };

    await axios
      .put(`/customers/${params.id}`, data)
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setMessage("Poprawnie edytowano klienta");

    getCustomer();

    setLoading(false);
  };

  const deleteCustomer = async () => {
    setLoading(true);
    await axios
      .delete(`/customers/${params.id}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(false);
    navigate(`/customers`);
  };

  const closeModal = () => {
    setMessage("");
  };

  return (
    <Page>
      <div className={styles.container}>
        <ReturnButton title="Powrót do listy klientów" url="/customers" />
        <div className={styles.controls}>
          <PrimaryButton
            onClick={() => navigate(`/customers/${params.id}/galleries`)}
            title="Galerie klienta"
            backgroundColor="#FFF"
            borderColor="#0099F0"
            color="#0099F0"
          />
          <PrimaryButton
            onClick={() => navigate(`/galleries/new-gallery?id=${params.id}`)}
            title="Dodaj galerię"
            backgroundColor="#0099F0"
            borderColor="#0099F0"
            color="#FFF"
          />
        </div>

        <div className={styles.profile}>
          <div className={styles.primary}>
            <FormInput
              onChange={onChange}
              value={customer.name || ""}
              type="text"
              label="Nazwa klienta"
              name="name"
              pattern=".{3,50}"
              errorMessage="Nazwa klienta jest wymagana i musi zawierać od 3 do 50 znaków."
              required
              className={styles.input}
            />
            <FormInput
              onChange={onChange}
              value={customer.email || ""}
              type="email"
              label="Adres email (nie można edytować)"
              name="email"
              pattern=".{3,50}"
              errorMessage="Podaj poprawny adres email"
              required
              disabled
              className={styles.input}
            />
          </div>
          <div className={styles.secondary}>
            <FormInput
              onChange={onChange}
              value={customer.passwordUnsecure || ""}
              type="text"
              label="Hasło (nie można edytować)"
              name="password"
              pattern="^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$"
              errorMessage="Hasło musi składać się z 8 - 20 znaków (bez polskich liter) oraz zawierać przynajmniej jedną cyfrę, jedną wielką literę i jeden znak specjalny."
              required
              disabled
              className={styles.input}
            />
            <FormInput
              onChange={onChange}
              value={customer.phone || ""}
              type="number"
              label="Numer telefonu"
              name="phone"
              pattern=""
              errorMessage=""
              className={styles.input}
            />
          </div>
          <div className={styles.notes}>
            <FormTextarea
              onChange={onChange}
              value={customer.notes || ""}
              type="text"
              label="Notatki"
              name="notes"
              pattern=""
              errorMessage=""
              className={styles.textarea}
            />
          </div>
          <div className={styles.actionButtons}>
            <PrimaryButton
              onClick={editCustomer}
              title="Zapisz zmiany"
              backgroundColor="#0099F0"
              borderColor="#0099F0"
              color="#FFF"
            />
          </div>
        </div>
      </div>
      {message ? <Alert message={message} close={() => setMessage("")} /> : ""}
    </Page>
  );
};

export default CustomerPage;
